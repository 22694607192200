import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { Button, Heading, Text, TextField } from '@fnox/eternal-smooth-ui';
import { errorMessage } from '@fnox/fabstracta-platform';
import { track } from '../common/tracking';
import { LoadingButton } from '../components';
import { Box } from '../components/Box';
import { Stack } from '../components/Stack';
import { useApi } from '../hooks/useApi';
import { LoginOption, ResponseTenantSelectContext, TenantUserOption } from './common';

type UserInfoDialogContentProps = {
	option: TenantUserOption;
	close: () => void;
	updateContextOptimistically: (
		updater: (prev: ResponseTenantSelectContext[]) => ResponseTenantSelectContext[]
	) => void;
	initialNote?: string | null;
};

export const NoteDialogContent = (props: UserInfoDialogContentProps) => {
	const option = props.option;

	const dispatch = useDispatch();
	const [note, setNote] = React.useState<string>(props.initialNote ?? '');
	const [request, callSetNote] = useApi(
		'PUT',
		`ui-login-v1/tenant-option/${props.option.tenantId}/${props.option.userId}/note`,
		{
			minDelay: true,
		}
	);

	return (
		<>
			<Stack gap="md" mb="xl">
				<Heading as="h2">{t`Anteckning`}</Heading>
				<Text>{t`Skriv en anteckning så kan det hjälpa dig komma ihåg till exempel vilken användare som är vilken. Anteckningen visas på användaren i listan.`}</Text>
				<TextField
					value={note}
					onChange={(e) => setNote(e.currentTarget.value)}
					title={t`Anteckning`}
					maxLength={64}
				></TextField>
			</Stack>
			<Box
				display="flex"
				justifyContent="flex-end"
				gap="md"
				flexDirection={{ base: 'column-reverse', sm: 'row' }}
				alignItems="stretch"
			>
				<Box>
					<Button secondary style={{ width: '100%' }} onClick={props.close}>
						{t`Avbryt`}
					</Button>
				</Box>
				<Box>
					<LoadingButton
						style={{ width: '100%' }}
						onClick={async () => {
							if (note === props.initialNote) {
								props.close();
								return;
							}

							const trimmedNote = note.trim();
							const operation = trimmedNote === '' ? 'delete' : note !== '' ? 'change' : 'add';
							const result = await callSetNote(trimmedNote);
							if (result.type === 'error') {
								dispatch(errorMessage('', t`Kunde inte spara anteckning`));
								track(`change_tenant_user_comment:${operation}:error`, result.error.error);
								return;
							}

							props.updateContextOptimistically((currentContext) => {
								return currentContext.map((item) =>
									item.type === 'CONTEXT'
										? {
												...item,
												options: item.options.map((contextOption) => {
													if (
														contextOption.tenantId === option.tenantId &&
														contextOption.type === 'USER' &&
														contextOption.userId === option.userId
													) {
														return { ...contextOption, note: trimmedNote } satisfies LoginOption;
													}
													return contextOption;
												}),
										  }
										: item
								);
							});

							track(`change_tenant_user_comment:${operation}:success`);
							props.close();
						}}
						loading={request.requesting}
					>{t`Spara`}</LoadingButton>
				</Box>
			</Box>
		</>
	);
};
