export function localeIncludes(string: string, searchString: string, locale: string): boolean {
	const collator = Intl.Collator(locale, { usage: 'search', sensitivity: 'base' });

	function localeNormalize(string: string) {
		return string
			.normalize('NFC')
			.toLocaleLowerCase(locale)
			.replaceAll(/./g, (letter) => {
				const normalizedLetter = letter.normalize('NFD').replace(/[\u0300-\u036f]/gi, '');
				return letter !== normalizedLetter && collator.compare(letter, normalizedLetter) === 0
					? normalizedLetter
					: letter;
			});
	}

	return localeNormalize(string).includes(localeNormalize(searchString));
}
