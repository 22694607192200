import React, { useState } from 'react';
import { jt, t } from 'ttag';
import { AwesomeIcon, AwesomeIconButton, COLORS, Heading, Text } from '@fnox/eternal-smooth-ui';
import { track } from '../common/tracking';
import { Box, Stack } from '../components';
import styles from './TenantSelect.module.css';

const HIDE_NEWS_LOCAL_STORAGE_KEY = 'hide_news';

export const News = () => {
	const [newsIsVisible, setNewsIsVisible] = useState(!localStorage.getItem(HIDE_NEWS_LOCAL_STORAGE_KEY));

	if (!newsIsVisible) {
		return null;
	}

	const dotsIcon = (
		<span key="dots-icon" style={{ whiteSpace: 'nowrap' }}>
			(&nbsp;<AwesomeIcon name="ellipsis-vertical" className={styles.iconInText}></AwesomeIcon>&nbsp;)
		</span>
	);

	return (
		<Box
			style={{ background: COLORS.BgMintGreenFull }}
			p="md"
			gap="md"
			borderRadius="md"
			display="flex"
			alignItems="center"
			justifyContent="space-between"
		>
			<Stack gap="md">
				<Box display="flex" gap="sm" alignItems="center">
					<AwesomeIcon name="info-circle" size="lg" />
					<Heading as="h3">{t`Funktioner i listan`}</Heading>
				</Box>
				<Box ml="md">{jt`Du kan göra följande via prickarna ${dotsIcon} på företagen i listan:`}</Box>
				<Stack flex={1} gap="xs" ml="md">
					<Box display="flex" alignItems="center">
						<Box mr="xs">
							<AwesomeIcon name="thumb-tack"></AwesomeIcon>
						</Box>
						<Text>{t`Fäst företag i toppen av listan som du använder ofta.`}</Text>
					</Box>
					<Box display="flex" alignItems="center">
						<Box mr="xs">
							<AwesomeIcon name="note"></AwesomeIcon>
						</Box>
						<Text>{t`Skriv en egen anteckning på dina användare så du kan skilja dem åt på det sätt som passar dig.`}</Text>
					</Box>
					<Box display="flex" alignItems="center">
						<Box style={{ marginRight: 6 }}>
							<AwesomeIcon name="link-simple-slash"></AwesomeIcon>
						</Box>
						<Text>{t`Koppla bort användare som du inte längre behöver tillgång till.`}</Text>
					</Box>
					<Box display="flex" alignItems="center">
						<Box mr="xs">
							<AwesomeIcon name="info-circle"></AwesomeIcon>
						</Box>
						<Text>{t`Se mer information om användaren.`}</Text>
					</Box>
				</Stack>
			</Stack>

			<Box style={{ alignSelf: 'flex-start' }}>
				<AwesomeIconButton
					aria-label={t`Stäng nyheter`}
					name="close"
					onClick={() => {
						setNewsIsVisible(false);
						localStorage.setItem(HIDE_NEWS_LOCAL_STORAGE_KEY, 'true');
						track('news:close');
					}}
				/>
			</Box>
		</Box>
	);
};
