/*
 * Supports streaming from RW with @Stream(JSON_ARRAY)
 * The format is a JSON array on the root level with streamable objects
 * [{obj1},{obj2},...]
 */
export function startAsyncParseJsonArrayFromReader<T>(
	reader: ReadableStreamDefaultReader<Uint8Array>,
	objectReceivedCallback: (data: T) => void,
	streamFinishedCallback: () => void
) {
	const decoder = new TextDecoder();
	let unparsedBuffer = '';

	(async () => {
		let first = true;
		// eslint-disable-next-line
		while (true) {
			const { done, value: encodedValue } = await reader.read();

			if (done) {
				break;
			}

			let newData = decoder.decode(encodedValue).trim();

			if (first && newData[0] === '[') {
				// First character should be [
				newData = newData.substring(1);
				first = false;
			}

			unparsedBuffer += newData;

			let startNextObject = 0;
			let parentheses = 0;
			let inString = false;
			for (let i = 0; i < unparsedBuffer.length; i++) {
				if (unparsedBuffer[i] === ',' && startNextObject === i) {
					// Remove , separator between objects on the root array level
					startNextObject++;
				} else if (unparsedBuffer[i] === '\\') {
					// Skip escaped character
					i++;
				} else if (unparsedBuffer[i] === '"') {
					inString = !inString;
				} else if (unparsedBuffer[i] === '{' && !inString) {
					parentheses++;
				} else if (unparsedBuffer[i] === '}' && !inString) {
					parentheses--;
					if (parentheses == 0) {
						const jsonObjectString = unparsedBuffer.substring(startNextObject, i + 1);
						startNextObject = i + 1;

						const jsonObject = JSON.parse(jsonObjectString);
						objectReceivedCallback(jsonObject);
					}
				}
			}

			// Remove parsed object from unparsed buffer
			unparsedBuffer = unparsedBuffer.substring(startNextObject);
		}

		streamFinishedCallback();
	})();
}
