import React, { ReactNode, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import { BorderBox, Box, DelayedSpinner, HeaderStripes, Link, Stack, useServerTime } from 'components';
import { jt, t } from 'ttag';
import {
	Alert,
	AwesomeIcon,
	COLORS,
	Dialog,
	Heading,
	MQBreakpoints,
	Spinner,
	Text,
	TextField,
} from '@fnox/eternal-smooth-ui';
import { platform, useMediaQuery } from '@fnox/fabstracta';
import { useLocale } from '../Localize';
import { localeIncludes } from '../common/localeIncludes';
import { track } from '../common/tracking';
import MainHeader from '../components/Header/MainHeader';
import { EmployeeSelectOption } from './EmployeeSelectOption';
import { GatherYourBusinesses } from './GatherYourBusinesses';
import { Invites } from './Invites';
import { News } from './News';
import { NotCopiedIdentityServiceMappingsDialog } from './NotCopiedIdentityServiceMappingsDialog';
import { NoteDialogContent } from './NoteDialogContent';
import { RemoveMappingDialogContent } from './RemoveMappingDialogContent';
import { SupportDrawer } from './SupportDrawer';
import styles from './TenantSelect.module.css';
import { TenantSelectEmployeeScreen } from './TenantSelectEmployeeScreen';
import { TenantSelectExpiredScreen } from './TenantSelectExpiredScreen';
import { TenantAction } from './TenantSelectOption';
import { UserInfoDialogContent } from './UserInfoDialogContent';
import { UserSelectOption } from './UserSelectOption';
import {
	ContextUserPreferences,
	LoginOption,
	ResponseTenantSelectContext,
	TenantSelectContext,
	TenantSorting,
	TenantUserOption,
	sortTenantOptions,
} from './common';
import { SelectedTenantOption, useTenantSelector } from './useTenantSelector';
import { faArrowsRepeat } from '@fortawesome/pro-solid-svg-icons';
import { parseUtcDate } from 'common/date';
import { redirect } from 'common/redirect';
import { UseApiStreamState, requestApi, useApi, useApiFetchStream } from 'hooks/useApi';
import { useTimer, useToggleTimeout } from 'hooks/useTimeout';

export type TodosContext = {
	[key: string]: number;
};

export type UnreadMessagesContext = {
	[key: string]: number;
};

export const TenantSelectScreen = () => {
	const { path } = useRouteMatch();
	const [contextRequest, , updateContextOptimistically] = useApiFetchStream<ResponseTenantSelectContext>(
		'ui-login-v1/tenant-select-context-v2'
	);

	const [canShowLoading] = useToggleTimeout(300);

	const serverDateString =
		(contextRequest.type === 'streaming' || contextRequest.type === 'streaming_started') &&
		contextRequest.fetchResponse.headers.get('date');
	useServerTime(serverDateString);

	const { context, todos, unreadMessages, hasTodosError } = useMemo(
		() => extractTenantContextData(contextRequest),
		[contextRequest]
	);

	if (contextRequest.type === 'error') {
		if (contextRequest.error.error === 'INVALID_SESSION') {
			redirect('/api/login-fortnox-id/web-login-v1');
			return null;
		}
		return <Redirect to={'/login-fortnox-id/error/' + contextRequest.error.error} />;
	}

	if (contextRequest.type === 'failure') {
		return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
	}

	if (!context) {
		// If streaming is finished and context has not arrived redirect to error page
		if (contextRequest.type && !contextRequest.requesting) {
			return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
		}
		return (
			<Box mt="xl">
				<DelayedSpinner />
			</Box>
		);
	}

	return (
		<Switch>
			<Route path={`${path}/expired`}>
				<TenantSelectExpiredScreen />
			</Route>
			<Route path={`${path}/employee`}>
				<TenantSelectEmployeeScreen />
			</Route>
			<Route>
				<TenantSelectContainer context={context}>
					<TenantSelectContent
						context={context}
						todos={todos}
						hasTodosError={hasTodosError}
						unreadMessages={unreadMessages}
						isLoadingContext={contextRequest.requesting}
						canShowLoading={canShowLoading}
						updateContextOptimistically={updateContextOptimistically}
					/>
					<SupportDrawer />
				</TenantSelectContainer>
			</Route>
		</Switch>
	);
};

const TenantSelectContainer = ({ context, children }: { context: TenantSelectContext; children: ReactNode }) => {
	return (
		<Box display="flex" flexDirection="column" className={styles.container}>
			<Box p="md" style={{ borderBottom: '1px solid #eee' }}>
				<MainHeader context={context} />
			</Box>
			<Box flex={1} p={{ base: 'md', md: 'xl', lg: '3xl' }} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
				{children}
			</Box>
		</Box>
	);
};

const TenantSelectContent = ({
	context,
	todos,
	hasTodosError,
	unreadMessages,
	isLoadingContext,
	canShowLoading,
	updateContextOptimistically,
}: {
	context: TenantSelectContext;
	todos: TodosContext;
	hasTodosError: boolean;
	isLoadingContext: boolean;
	unreadMessages: UnreadMessagesContext;
	canShowLoading: boolean;
	updateContextOptimistically: (
		updater: (currentContext: ResponseTenantSelectContext[]) => ResponseTenantSelectContext[]
	) => void;
}) => {
	const [expired, setExpired] = useState(false);
	const { registerForm, selectTenantOption, registerTenantIdField, registerIdField, selectingOption } =
		useTenantSelector();

	useTimer(() => setExpired(true), parseUtcDate(context.expiresAt));

	if (expired) {
		return <Redirect to="/login-fortnox-id/tenant-select/expired" />;
	}

	const selectedCompanyName = selectingOption ? selectingOption.companyName ?? selectingOption.tenantId : undefined;
	const isLoadingSelectedTenantOption = selectingOption;
	const hasTenantUsers = context.options.length > 0;

	return (
		<Box style={{ maxWidth: '584px', margin: '0 auto' }}>
			<Stack gap="xl">
				<Stack gap="xl">
					<span style={{ lineHeight: '43px' }}>
						<Heading as="h1" className={styles.heading}>
							{hasTenantUsers ? t`Välj företag` : t`Kom igång med ditt första företag`}
						</Heading>
					</span>
					<HeaderStripes />
				</Stack>

				{isLoadingSelectedTenantOption ? (
					<BorderBox gap="md" pt="lg">
						<Spinner enabled />
						{selectingOption && <Heading as="h4">{jt`Öppnar ${selectedCompanyName}`}</Heading>}
					</BorderBox>
				) : (
					<Stack gap="xl">
						{hasTenantUsers ? (
							<>
								<News />
								<Invites />
								<TenantSelectorAlerts context={context} hasTodoError={hasTodosError} />
								<Stack gap="3xl">
									<TenantSelectList
										context={context}
										todos={todos}
										unreadMessages={unreadMessages}
										isLoadingContext={isLoadingContext}
										canShowLoading={canShowLoading}
										onTenantSelect={selectTenantOption}
										updateContextOptimistically={updateContextOptimistically}
									/>
									<GatherYourBusinesses context={context} hasTenantUsers={hasTenantUsers} />
								</Stack>
							</>
						) : (
							<>
								<Invites />
								<TenantSelectorAlerts context={context} hasTodoError={hasTodosError} />
								<GatherYourBusinesses context={context} hasTenantUsers={hasTenantUsers} />
							</>
						)}
					</Stack>
				)}

				<form {...registerForm()}>
					<input {...registerTenantIdField()} />
					<input {...registerIdField()} />
				</form>

				<NotCopiedIdentityServiceMappingsDialog invalidMappings={context.notCopiedIdentityServiceMappings ?? []} />
			</Stack>
		</Box>
	);
};

function TogglingLink(props: { children: React.ReactNode; onClick: () => void }) {
	return (
		<Text size="small">
			<span>{t`Sortering`}: </span>
			<a href="javascript:void(0)" className={styles.togglingLink} onClick={props.onClick}>
				<AwesomeIcon name={faArrowsRepeat.iconName} className={styles.togglingIcon} />
				<span>{props.children}</span>
			</a>
		</Text>
	);
}

const TenantSelectList = ({
	context,
	todos,
	canShowLoading,
	isLoadingContext,
	unreadMessages,
	onTenantSelect,
	updateContextOptimistically,
}: {
	context: TenantSelectContext;
	todos: TodosContext;
	canShowLoading: boolean;
	isLoadingContext: boolean;
	unreadMessages: UnreadMessagesContext;
	onTenantSelect: (selected: SelectedTenantOption) => void;
	updateContextOptimistically: (
		updater: (currentContext: ResponseTenantSelectContext[]) => ResponseTenantSelectContext[]
	) => void;
}) => {
	const tenantOptions = context.options;
	const tenantCountMap = getTenantCountMap(tenantOptions);
	const companyNameCountMap = getCompanyNameCountMap(tenantOptions);

	const [searchFilter, setSearchFilter] = useState('');
	const appliedSearchFilter = searchFilter.trim();
	const filteredOptions = filterTenantOptions(tenantOptions, appliedSearchFilter, useLocale());

	const [isUserInfoDialogOpen, setIsUserInfoDialogOpen] = useState(false);
	const [isRemoveMappingDialogOpen, setIsRemoveMappingDialogOpen] = useState(false);
	const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<LoginOption | null>(null);

	const isLargerScreen = useMediaQuery(MQBreakpoints.Sm);

	// Determines possible actions the tenant option has
	function getPossibleActions(option: LoginOption): TenantAction[] {
		const actions: TenantAction[] = [];

		if (option.type === 'USER') {
			actions.push({
				icon: 'info-circle',
				label: t`Om användaren`,
				onSelect: () => {
					setIsUserInfoDialogOpen(true);
					setSelectedOption(option);
					track(`about_tenant_user:open_dialog`);
				},
			});

			actions.push({
				icon: 'thumb-tack',
				label: option.pinned ? t`Lossa från toppen` : t`Fäst i toppen`,
				onSelect: async () => {
					updateContextOptimistically((currentContext) => {
						return currentContext.map((item) =>
							item.type === 'CONTEXT'
								? {
										...item,
										options: item.options.map((contextOption) => {
											if (
												contextOption.tenantId === option.tenantId &&
												contextOption.type === 'USER' &&
												contextOption.userId === option.userId
											) {
												return { ...contextOption, pinned: !contextOption.pinned };
											}
											return contextOption;
										}),
								  }
								: item
						);
					});

					const result = await requestApi(
						'PUT',
						`ui-login-v1/tenant-option/${option.tenantId}/${option.userId}/pinned`,
						!option.pinned
					);

					const operation = option.pinned ? 'unpin' : 'pin';
					track(`${operation}_tenant_user:${result.type}`);
				},
			});

			actions.push({
				icon: 'note',
				label: option.note ? t`Ändra anteckning` : t`Lägg till anteckning`,
				onSelect: () => {
					setIsNoteDialogOpen(true);
					setSelectedOption(option);
					track('change_tenant_user_comment:open_dialog');
				},
			});
		}

		actions.push({
			color: COLORS.BgRedFull,
			icon: 'link-simple-slash',
			label: t`Koppla bort användaren`,
			onSelect: () => {
				setIsRemoveMappingDialogOpen(true);
				setSelectedOption(option);
				track('disconnect_tenant_user:open_dialog');
			},
		});

		return actions;
	}

	type SortingState = { nextSorting: TenantSorting; label: string };
	const tenantSortingStates: {
		ALPHABETICAL: SortingState;
		LAST_USED: SortingState;
	} = {
		ALPHABETICAL: {
			nextSorting: 'LAST_USED',
			label: t`Bokstavsordning A-Ö`,
		},
		LAST_USED: {
			nextSorting: 'ALPHABETICAL',
			label: t`Senaste inloggad`,
		},
	};

	const [, saveUserPreferences] = useApi<ContextUserPreferences, void>('POST', 'user-preferences-v1/save');
	const [tenantSorting, setTenantSorting] = useState<TenantSorting>(
		context.userPreferences.tenantSorting ?? 'ALPHABETICAL'
	);
	const updateTenantSorting = (nextSorting: TenantSorting) => {
		void saveUserPreferences({ tenantSorting: nextSorting });
		setTenantSorting(nextSorting);
		track('sorting:click', nextSorting.toString());
	};

	const options = sortTenantOptions(filteredOptions, tenantSorting);
	const matchingCompaniesText = <strong>{jt`${options.length} av dina ${tenantOptions.length} företag`}</strong>;

	return (
		<div>
			{tenantOptions.length > 9 && (
				<Box mb="md">
					<TextField
						title={isLargerScreen ? t`Filtrera på företagsnamn, org.nr eller anteckning` : t`Filtrera`}
						icon={<AwesomeIcon name="search" />}
						iconPosition="left"
						clearIcon
						onClearIconClick={() => setSearchFilter('')}
						onChange={(e) => setSearchFilter(e.currentTarget.value)}
						value={searchFilter}
					/>
					{appliedSearchFilter != '' && <Box mt="xs">{jt`Filtreringen matchar ${matchingCompaniesText}`}</Box>}
				</Box>
			)}
			{options.length > 1 && (
				<Box mb="xs" display="flex" flexDirection="column" alignItems="flex-end">
					<TogglingLink
						onClick={() => {
							updateTenantSorting(tenantSortingStates[tenantSorting].nextSorting);
						}}
					>
						{tenantSortingStates[tenantSorting].label}
					</TogglingLink>
				</Box>
			)}
			<Stack testID="tenant-options" gap="xs">
				{options.map((o, i) => {
					const actions = getPossibleActions(o);

					if (o.type === 'EMPLOYEE') {
						return <EmployeeSelectOption key={i} option={o} onSelect={onTenantSelect} actions={actions} />;
					}

					return (
						<UserSelectOption
							key={i}
							option={o}
							numTodos={todos[o.tenantId + '-' + o.userId]}
							numUnreadMessages={unreadMessages[o.tenantId + '-' + o.userId]}
							canShowLoading={canShowLoading}
							isLoadingContext={isLoadingContext}
							onSelect={onTenantSelect}
							identifierHint={getIdentifierHintString(o, tenantCountMap, companyNameCountMap)}
							actions={actions}
						/>
					);
				})}
			</Stack>

			<Dialog
				className={styles.tenantSelectDialog}
				open={isRemoveMappingDialogOpen}
				onClose={() => setIsRemoveMappingDialogOpen(false)}
			>
				{selectedOption != null && (
					<RemoveMappingDialogContent
						context={context}
						updateContextOptimistically={updateContextOptimistically}
						loginOption={selectedOption}
						close={() => setIsRemoveMappingDialogOpen(false)}
					/>
				)}
			</Dialog>

			<Dialog
				className={styles.tenantSelectDialog}
				open={isUserInfoDialogOpen}
				onClose={() => setIsUserInfoDialogOpen(false)}
			>
				{selectedOption != null && selectedOption.type === 'USER' && (
					<UserInfoDialogContent user={selectedOption} close={() => setIsUserInfoDialogOpen(false)} />
				)}
			</Dialog>

			<Dialog className={styles.tenantSelectDialog} open={isNoteDialogOpen} onClose={() => setIsNoteDialogOpen(false)}>
				{selectedOption != null && selectedOption.type === 'USER' && (
					<NoteDialogContent
						option={selectedOption}
						close={() => setIsNoteDialogOpen(false)}
						updateContextOptimistically={updateContextOptimistically}
						initialNote={selectedOption.note}
					/>
				)}
			</Dialog>
		</div>
	);
};

function getIdentifierHintString(
	option: TenantUserOption,
	tenantCountMap: Record<number, number>,
	companyNameCountMap: Record<number, number> | Record<number, never>
): string {
	const identifierHints: string[] = [];
	const tenantHasMultipleMappings = tenantCountMap[option.tenantId] > 1;
	const companyNameUsedByMultipleOptions = companyNameCountMap[getCompanyName(option)] > 1;

	if (companyNameUsedByMultipleOptions) {
		identifierHints.push(option.tenantId.toString());
	}

	if (tenantHasMultipleMappings) {
		identifierHints.push(`ID: ${option.userId}`);
	}

	return identifierHints.join(' ');
}

export function getCompanyNameCountMap(authList: LoginOption[]): Record<string, number> {
	// Collect all company names and the tenantIds they are associated with
	const nameToTenantIdMap: { [p: string]: number[] } = authList.reduce((map, option) => {
		const tenantId = option.tenantId;
		const companyName = getCompanyName(option);
		const currentTenantIds = map[companyName] ?? [];
		return { ...map, [companyName]: [...currentTenantIds, tenantId] };
	}, {});

	// Count the amount of tenantIds associated with each company name
	return Object.entries(nameToTenantIdMap).reduce((countMap, [companyName, tenantIds]) => {
		const uniqueTenantIds: number[] = Array.from(new Set(tenantIds));
		return { ...countMap, [companyName]: uniqueTenantIds.length };
	}, {});
}

function getTenantCountMap(options: LoginOption[]) {
	return options.reduce((map, o) => ({ ...map, [o.tenantId]: (map[o.tenantId] ?? 0) + 1 }), {});
}

function getCompanyName(option: LoginOption) {
	return option.companyName ?? option.tenantId.toString();
}

function TenantSelectorAlerts({
	context,
	hasTodoError,
}: {
	context: TenantSelectContext;
	hasTodoError: boolean;
}): React.ReactNode {
	const dispatch = useDispatch();

	const hasNotCopiedIdentityServiceMappings =
		context.notCopiedIdentityServiceMappings && context.notCopiedIdentityServiceMappings.length > 0;

	// Has a tenant option with an empty company name
	const hasEmptyCompanyName = context.options.filter((option: LoginOption) => option.companyName === null).length > 0;

	// Has incomplete options in list of tenant options. Likely because information could not be fetched for one or more tenants
	const hasIncompleteOptionsList = context.incompleteOptionsList;

	// Has incomplete option details. Likely all information could not be fetched for one or more tenants
	const hasIncompleteOptionsDetails = context.incompleteOptionsDetails;

	return (
		<Stack className={styles.hideIfEmpty} gap="xs">
			{hasNotCopiedIdentityServiceMappings && (
				<Alert type="warning">
					<Box mb="xs">
						{t`Vi kunde inte koppla alla företag där du tidigare loggade in med BankID till ditt Fortnox ID.`}
					</Box>
					<Link
						to="#"
						onClick={() => dispatch(platform.toggleModalShow('notCopiedIdentityServiceMappingsDialog'))}
						variant="internal"
					>{t`Visa ej kopplade företag`}</Link>
				</Alert>
			)}

			{hasIncompleteOptionsList && (
				<Alert type="warning">
					{t`Det gick inte att hämta information om ett eller flera företag just nu. Listan kan tillfälligt visa färre företag än vanligt. Försök igen senare eller kontakta vår support om felet kvarstår.`}
				</Alert>
			)}

			{hasIncompleteOptionsDetails && (
				<Alert type="warning">
					{t`Det gick inte att hämta all information om ett eller flera företag just nu. Du kan fortfarande logga in medan vi löser problemet.`}
				</Alert>
			)}

			{hasEmptyCompanyName && (
				<Alert type="info">
					{t`Du har företag som saknar namn. Fyll i ditt företagsnamn under företagsuppgifter så syns det här.`}
				</Alert>
			)}

			{hasTodoError && (
				<Alert type="warning">
					{t`Kunde inte ladda uppgifter om vad som finns att göra på grund av ett temporärt fel. Det kan finnas viktiga saker att göra även om det inte syns tills felet är åtgärdat.`}
				</Alert>
			)}
		</Stack>
	);
}

function extractTenantContextData(contextRequest: UseApiStreamState<ResponseTenantSelectContext>) {
	let context: TenantSelectContext | null = null;
	const todos: TodosContext = {};
	const unreadMessages: UnreadMessagesContext = {};
	if (contextRequest.type === 'streaming') {
		contextRequest.data.find((item) => item.type === 'CONTEXT');

		for (const item of contextRequest.data) {
			if (item.type === 'CONTEXT') {
				context = item;
			} else if (item.type === 'TODOS_COUNT') {
				todos[item.tenantId + '-' + item.userId] = item.todosCount;
			} else if (item.type === 'UNREAD_MESSAGES_COUNT') {
				unreadMessages[item.tenantId + '-' + item.userId] = item.count;
			}
		}
	}

	const hasTodosError =
		!!context &&
		!contextRequest.requesting &&
		context.options.some((o) => o.type == 'USER' && todos[o.tenantId + '-' + o.userId] === undefined && !o.isBureau);

	return {
		context,
		todos,
		unreadMessages,
		hasTodosError,
	};
}

function filterTenantOptions(options: LoginOption[], searchFilter: string, locale: string) {
	if (!searchFilter) {
		return options;
	}

	return options.filter(
		(o) =>
			(o.companyName && localeIncludes(o.companyName, searchFilter, locale)) ||
			(o.organizationNumber && o.organizationNumber.replaceAll('-', '').includes(searchFilter.replaceAll('-', ''))) ||
			(o.type === 'USER' && o.note && localeIncludes(o.note, searchFilter, locale))
	);
}
