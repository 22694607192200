import React from 'react';
import { Box, LoadingButton, Stack } from 'components';
import { jt, t } from 'ttag';
import { Alert, Button, Heading, Text } from '@fnox/eternal-smooth-ui';
import { track } from '../common/tracking';
import { useApi } from '../hooks/useApi';
import { LoginOption, ResponseTenantSelectContext, TenantSelectContext } from './common';

type DeleteTenantOptionParams =
	| {
			tenantId: number;
			userId: number;
	  }
	| {
			tenantId: number;
			employeeId: string;
	  };

type RemoveMappingDialogProps = {
	context: TenantSelectContext;
	loginOption: LoginOption;
	updateContextOptimistically: (
		updater: (currentContext: ResponseTenantSelectContext[]) => ResponseTenantSelectContext[]
	) => void;
	close: () => void;
};

function isSameLoginOption(loginOption: LoginOption, loginOption2: LoginOption) {
	if (loginOption.type === 'USER' && loginOption2.type === loginOption.type) {
		return loginOption.tenantId === loginOption2.tenantId && loginOption.userId === loginOption2.userId;
	}

	if (loginOption.type === 'EMPLOYEE' && loginOption2.type === loginOption.type) {
		return loginOption.tenantId === loginOption2.tenantId && loginOption.employeeId === loginOption2.employeeId;
	}

	return false;
}

export const RemoveMappingDialogContent = (props: RemoveMappingDialogProps) => {
	const [request, deleteTenantOption] = useApi<DeleteTenantOptionParams, void>('DELETE', 'ui-login-v1/tenant-option');

	const onConfirm = async () => {
		const result = await deleteTenantOption(
			props.loginOption.type === 'USER'
				? {
						tenantId: props.loginOption.tenantId,
						userId: props.loginOption.userId,
				  }
				: {
						tenantId: props.loginOption.tenantId,
						employeeId: props.loginOption.employeeId,
				  }
		);

		if (result.type === 'success') {
			props.updateContextOptimistically((currentContext) =>
				currentContext.map((item) =>
					item.type === 'CONTEXT'
						? {
								...item,
								options: item.options.filter((loginOption) => !isSameLoginOption(loginOption, props.loginOption)),
						  }
						: item
				)
			);
			props.close();
			track(`disconnect_tenant_user:${result.type}`);
		}
	};

	const onCancel = props.close;

	const styledUserName =
		props.loginOption.type === 'USER' ? <strong key="userName">{props.loginOption.name}</strong> : null;
	const styledCompanyName = <strong key="company">{props.loginOption.companyName}</strong>;

	if (request.type === 'error' && request.error.error === 'can.not.remove.last.sysadmin') {
		return (
			<>
				<Stack gap="md" mb="xl">
					<Heading as="h2">{t`Det går inte att koppla bort användaren`}</Heading>
					<Text>
						{jt`Användaren är den sista systemadministratören på företaget och du kan därför inte koppla bort den eftersom ingen längre skulle kunna administrera företaget. Gör någon annan användare till systemadministratör innan du tar bort kopplingen.`}
					</Text>
				</Stack>
				<Box display="flex" justifyContent="flex-end" gap="md">
					<Button secondary onClick={onCancel}>
						{t`Stäng`}
					</Button>
				</Box>
			</>
		);
	}

	return (
		<>
			<Stack gap="md" mb="xl">
				<Heading as="h2">{t`Koppla bort användaren`}</Heading>
				<Text>
					{jt`När du fortsätter kommer du inte längre kunna logga in på användaren ${styledUserName} i abonnemanget ${styledCompanyName}. Om du skulle ångra dig behöver du be din systemadministratör skicka en ny inbjudan.`}
				</Text>
			</Stack>
			{request.type === 'error' && (
				<Box mb="xl">
					<Alert type="error">{t`Misslyckades ta bort användarkopplingen`}</Alert>
				</Box>
			)}
			<Box
				display="flex"
				justifyContent="flex-end"
				gap="md"
				flexDirection={{ base: 'column-reverse', sm: 'row' }}
				alignItems="stretch"
			>
				<Box>
					<Button secondary style={{ width: '100%' }} onClick={onCancel}>
						{t`Avbryt`}
					</Button>
				</Box>
				<Box>
					<LoadingButton
						destructive
						style={{ width: '100%' }}
						onClick={onConfirm}
						loading={request.requesting}
					>{t`Koppla bort`}</LoadingButton>
				</Box>
			</Box>
		</>
	);
};
