import React, { useEffect, useRef } from 'react';
import { TextField } from '@fnox/eternal-smooth-ui';

type TextInputProps = {
	/**
	 * An explicit error message that will be shown, styled the same way as a validation error. If this is set, the validators won't run.
	 */
	explicitErrorMessage?: string;
} & Omit<React.ComponentProps<typeof TextField>, 'inputRef'>;

export type FieldWithValidation = {
	value: string;
	error?: string;
};

/**
 * Wrapper component for TextField from Eternal Smooth UI to introduce a way to set an explicit error message through a hacky way.
 * Notes: if you use the disabled prop, you need to keep track of the state determining that status. You then have to derive
 * a state for the error message. For example validationError = !disabled && errorMessage ? errorMessage : undefined;
 * @param props
 * @constructor
 */
export const TextInput = (props: TextInputProps) => {
	const inputRef = useRef<HTMLInputElement>();

	// Run a custom validator that will give the explicit error message priority and only run the provided validators if no explicit error message is set
	function validatorOverride(value, validators: ((value?: string) => true | string)[]): true | string {
		if (typeof props.explicitErrorMessage === 'string') {
			return props.explicitErrorMessage;
		}

		// Run the validators and return the first error message, otherwise everything passes and return true
		return validators.map((validator) => validator(value)).find((result) => typeof result === 'string') ?? true;
	}

	// Hack to trigger so the validators on the TextField component are ran
	function triggerValidation() {
		// Save the current element that has focus, so we can return the focus to it after the blur
		const focusedElement = document.activeElement as HTMLElement;
		inputRef.current?.focus();
		inputRef.current?.blur();
		focusedElement.focus();
	}

	useEffect(() => {
		if (props.explicitErrorMessage) {
			triggerValidation();
		}
	}, [props.explicitErrorMessage]);

	return (
		<TextField
			{...props}
			validators={[(value) => validatorOverride(value, props.validators ?? [])]}
			inputRef={inputRef}
		/>
	);
};
TextInput.displayName = 'TextInput';
