import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { OnboardInviteInvalidScreen } from './Invalid';
import { OnboardInviteNotFoundScreen } from './NotFound';

export function OnboardRoutes() {
	return (
		<Switch>
			<Route path="/login-fortnox-id/onboard/invalid">
				<OnboardInviteInvalidScreen />
			</Route>
			<Route path="/login-fortnox-id/onboard/not-found">
				<OnboardInviteNotFoundScreen />
			</Route>
		</Switch>
	);
}
