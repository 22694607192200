import React, { MouseEvent, useState } from 'react';
import { Box, Stack } from 'components';
import { t } from 'ttag';
import { Alert, Button, COLORS, Dialog, Heading, Text } from '@fnox/eternal-smooth-ui';
import { track } from '../common/tracking';
import { DeclineInviteDialogContent } from './DeclineInviteDialogContent';
import styles from './Invites.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApiFetch } from 'hooks/useApi';

export type Invite = {
	id: string;
	companyName: string | null;
	organizationNumber: string | null;
	invitedBy: string;
	tenantId: number;
	userId: number;
};

export type InviteResponse = {
	invites: Invite[];
};

export function Invites() {
	const [invites, , updateInvites] = useApiFetch<InviteResponse>('ui-login-v1/invites');
	const [declineInvite, setDeclineInvite] = useState<Invite | null>(null);

	if (invites.requesting) {
		return null;
	}

	if (invites.type !== 'success') {
		return <Alert type="warning">{t`Misslyckades att slå upp eventuella inbjudningar.`}</Alert>;
	}

	if (invites.data.invites.length === 0) {
		return null;
	}

	const sortedInvites = invites.data.invites.sort(
		(a, b) =>
			(a.companyName ?? '').localeCompare(b.companyName ?? '') ||
			(a.organizationNumber ?? '').localeCompare(b.organizationNumber ?? '') ||
			a.tenantId - b.tenantId
	);

	return (
		<Box testID="invite-options" backgroundColor="beige-light" p="md" borderRadius="md">
			<Box mb="xs">
				<Heading as="h3">{t`Inbjudningar`}</Heading>
			</Box>
			<Box mb="md">{t`Om du accepterar inbjudan kommer du kunna logga in i företaget.`}</Box>
			<Stack gap="xs">
				{sortedInvites.map((invite) => (
					<InviteContent
						key={invite.id}
						invite={invite}
						declineInvite={() => {
							setDeclineInvite(invite);
							track('decline_invite:open_dialog');
						}}
					/>
				))}
			</Stack>
			<Dialog className={styles.dialog} open={declineInvite != null} onClose={() => setDeclineInvite(null)}>
				{declineInvite != null && (
					<DeclineInviteDialogContent
						invite={declineInvite}
						updateInvites={updateInvites}
						close={() => setDeclineInvite(null)}
					/>
				)}
			</Dialog>
		</Box>
	);
}

function InviteContent(props: { invite: Invite; declineInvite: () => void }) {
	const [isAccepting, setIsAccepting] = useState(false);

	function handleSubmit() {
		setIsAccepting(true);
		track('accept_invite:click');
	}

	function onClickDeclineButton(ev: MouseEvent<HTMLButtonElement> | undefined) {
		ev?.preventDefault();
		props.declineInvite();
	}

	return (
		<div className={styles.invite}>
			<form method="post" action="/api/login-fortnox-id/ui-login-v1/invites/accept" onSubmit={handleSubmit}>
				<Box display="flex" gap="xs" flexDirection="column">
					<input type="hidden" name="id" value={props.invite.id} />
					<Box flex={1}>
						<Box>{props.invite.companyName || props.invite.tenantId}</Box>
						{props.invite.organizationNumber && (
							<Text size="small" color={COLORS.ReadOnly}>
								{props.invite.organizationNumber}
							</Text>
						)}
						{props.invite.invitedBy && (
							<Text size="small">
								<Text as="span" color={COLORS.ReadOnly} size="small">{t`Inbjuden av`}</Text> {props.invite.invitedBy}
							</Text>
						)}
					</Box>
					<Box display="flex" gap="sm" justifyContent="flex-end">
						<Button
							icon={<FontAwesomeIcon icon={['fal', 'close']} size="lg" />}
							iconPosition="left"
							size="small"
							secondary
							onClick={onClickDeclineButton}
							testID={`decline-invite-button-${props.invite.tenantId}-${props.invite.userId}`}
						>{t`Avvisa`}</Button>
						<Button
							type="submit"
							icon={<FontAwesomeIcon icon={['fal', 'check']} size="lg" />}
							iconPosition="left"
							size="small"
							shouldGiveFeedback
							isLoading={isAccepting}
							testID={`accept-invite-button-${props.invite.tenantId}-${props.invite.userId}`}
						>{t`Acceptera`}</Button>
					</Box>
				</Box>
			</form>
		</div>
	);
}
