import React from 'react';
import { Box, LoadingButton, Stack } from 'components';
import { t } from 'ttag';
import { Alert, Button, Heading, Text } from '@fnox/eternal-smooth-ui';
import { track } from '../common/tracking';
import { useApi } from '../hooks/useApi';
import { Invite, InviteResponse } from './Invites';

type DeclineInviteParams = {
	id: string;
};

type DeclineInviteDialogContentProps = {
	invite: Invite;
	updateInvites: (updater: (response: InviteResponse) => InviteResponse) => void;
	close: () => void;
};

export const DeclineInviteDialogContent = (props: DeclineInviteDialogContentProps) => {
	const [request, declineInvite] = useApi<DeclineInviteParams>('POST', 'ui-login-v1/invites/decline');

	async function onConfirm() {
		const result = await declineInvite({ id: props.invite.id });

		if (result.type === 'success') {
			props.updateInvites((response) => ({
				invites: response.invites.filter((it) => it.id !== props.invite.id),
			}));

			props.close();
			track('decline_invite:success');
		}
	}

	return (
		<>
			<Stack gap="md" mb="xl">
				<Heading as="h2">{t`Avvisa inbjudan`}</Heading>
				<Text>{t`Om du skulle ångra dig behöver du be din systemadministratör skicka en ny inbjudan.`}</Text>
			</Stack>
			{request.type === 'error' && (
				<Box mb="xl">
					<Alert type="error">{t`Misslyckades att avvisa inbjudan`}</Alert>
				</Box>
			)}
			<Box
				display="flex"
				justifyContent="flex-end"
				gap="md"
				flexDirection={{ base: 'column-reverse', sm: 'row' }}
				alignItems="stretch"
			>
				<Box>
					<Button secondary style={{ width: '100%' }} onClick={props.close}>
						{t`Avbryt`}
					</Button>
				</Box>
				<Box>
					<LoadingButton
						destructive
						style={{ width: '100%' }}
						onClick={onConfirm}
						loading={request.requesting}
					>{t`Avvisa`}</LoadingButton>
				</Box>
			</Box>
		</>
	);
};
