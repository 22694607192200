import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { jt, t } from 'ttag';
import { AwesomeIcon, COLORS, Container, Heading, Link, Text } from '@fnox/eternal-smooth-ui';
import humanQuestion from '../../images/human-question.svg';
import { SignInAgainButton } from '../SignInAgainButton';
import styles from './ErrorView.module.css';
import { Box } from 'components/Box';
import { Header } from 'components/Header';

export const ErrorView = (props: { showGeneric?: boolean }) => (
	<Box display="flex" justifyContent="center">
		<Box display="flex" flexDirection="column">
			<Container maxWidth="sm" centerHorizontally padding="md">
				<Header mb={{ base: 'md', sm: 'xl' }} />
				<Box mb="xl">{props.showGeneric ? <GenericErrorContent /> : <SpecificErrorContent />}</Box>
			</Container>
		</Box>
		<Box
			display={{ base: 'none', lg: 'flex' }}
			pt="sm"
			mt="2xl"
			alignItems="flex-start"
			justifyContent="center"
			style={{ width: '400px' }}
		>
			<img src={humanQuestion} role="presentation" alt="" style={{ height: '400px' }} />
		</Box>
	</Box>
);

const GenericErrorContent = () => (
	<>
		<ErrorHeading>{t`Hoppsan, det blev lite fel`}</ErrorHeading>
		<Text>
			{t`Vi vet om felet och felsöker vad som kan ha gått snett. Prova gärna att uppdatera sidan igen om några minuter. Om det fortfarande inte fungerar kan du kika på vår driftinformation nedan`}
		</Text>
		<ErrorFooter />
	</>
);

const SpecificErrorContent = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<Switch>
				<Route path={`${path}/USER_ENVIRONMENT_ERROR`}>
					<ErrorHeading>{t`Hoppsan, det blev lite fel`}</ErrorHeading>
					<Text as="p">{t`Det gick inte att logga in och det verkar bero på problem med din webbläsare eller enhet.`}</Text>
					<Text>
						<strong>{t`Du kan prova följande för att avhjälpa problemet`}</strong>
					</Text>
					<ul>
						<li>{t`Gå tillbaka till inloggningsskärmen och försök igen från början.`}</li>
						<li>{t`Starta om webbläsaren och försök igen.`}</li>
						<li>{t`Prova i en annan webbläsare.`}</li>
						<li>{t`Prova att stänga av tillägg i din webbläsare som påverkar kakor, nätverk eller webbplatser på något sätt.`}</li>
					</ul>
					<ErrorFooter />
				</Route>
				<Route path={`${path}/SYSTEM_ERROR`}>
					<SystemError />
				</Route>
				<Route path={`${path}/UNAUTHORIZED`}>
					<ErrorHeading>{t`Du är utloggad`}</ErrorHeading>
					<Text as="p">{t`Detta beror troligen på att du försökt använda flera konton samtidigt. Du kan endast vara inloggad på ett konto i taget.`}</Text>
					<SignInAgainButton />
				</Route>
				<Route path={`${path}/TENANT_LOCKED`}>
					<ErrorHeading>{t`Abonnemang låst`}</ErrorHeading>
					<Text>{t`Abonnemang kan låsas av olika anledningar, men den vanligaste är att det finns en obetald förfallen faktura. Be din systemadministratör ta kontakt med Fortnox support.`}</Text>
					<SignInAgainButton />
					<ContactMessage incidentQuestion={false} />
				</Route>
				<Route path={`${path}/TENANT_INACTIVE`}>
					<ErrorHeading>{t`Inaktivt abonnemang`}</ErrorHeading>
					<Text>{t`Kontakta din systemadministratör för att aktivera abonnemanget igen.`}</Text>
					<SignInAgainButton />
					<ContactMessage incidentQuestion={false} />
				</Route>
				<Route path={`${path}/TENANT_CONVERSION`}>
					<ErrorHeading>{t`Konvertering pågår`}</ErrorHeading>
					<Text>
						{t`Filöverföring i samband med konvertering pågår. När allting är klart kommer ett mejl skickas ut och då kan ni logga in igen.`}
					</Text>
					<SignInAgainButton />
				</Route>
				<Route path={`${path}/USER_INACTIVE`}>
					<ErrorHeading>{t`Inaktiverad användare`}</ErrorHeading>
					<Text>{t`Din användare är inaktiverad. Kontakta din systemadministratör.`}</Text>
					<SignInAgainButton />
				</Route>
				<Route path={`${path}/TENANT_MAINTENANCE`}>
					<ErrorHeading>{t`Underhåll pågår`}</ErrorHeading>
					<Text>{t`Vi utför en uppdatering och under tiden kommer ditt abonnemang inte vara tillgängligt. Vi är strax tillbaka.`}</Text>
					<ErrorFooter />
				</Route>
				<Route path={`${path}/FORTNOX_ACCOUNT_NOT_ALLOWED`}>
					<ErrorHeading>{t`Felaktig inloggning`}</ErrorHeading>
					<Text>{t`Tyvärr kan du som har portalabonnemang bara logga in via din portal.`}</Text>
				</Route>
				<Route path={`${path}/INVALID_SESSION`}>
					<ErrorHeading>{t`Du är utloggad`}</ErrorHeading>
					<Text>{t`Gå tillbaka till inloggningsskärmen och försök igen från början.`}</Text>
					<SignInAgainButton />
				</Route>
				<Route path={`${path}/REQUESTED_TENANT_USER_NOT_MAPPED`}>
					<ErrorHeading>{t`Användaren är inte kopplad`}</ErrorHeading>
					<Text as="p">{t`Länken du använde förutsätter att du har tillgång till en specifik användare i ett specifikt företag men det Fortnox ID du loggade in med är inte kopplat till den användaren.`}</Text>
					<Text>{t`Logga in med rätt Fortnox ID eller begär en inbjudan till företaget du behöver logga in på.`}</Text>
				</Route>
				<Route>
					<ErrorHeading>{t`Hoppsan, det blev lite fel`}</ErrorHeading>
					<Text>{t`Felaktigt anrop till denna felsida. Gå tillbaka till inloggningsskärmen och försök igen från början.`}</Text>
					<ErrorFooter />
				</Route>
			</Switch>
		</>
	);
};

const SystemError = () => (
	<>
		<ErrorHeading>{t`Hoppsan, det blev lite fel`}</ErrorHeading>
		<Text>
			{t`Det gick inte att logga in just nu på grund av ett tekniskt fel. Dessa fel beror antagligen på fel hos oss. Vi gör vårt bästa för att lösa problemet. Försök igen från inloggningsskärmen vid ett senare tillfälle.`}
		</Text>
		<ErrorFooter />
	</>
);

const ErrorHeading = (props: { children: string }) => (
	<Box mb="md">
		<Heading as="h1">{props.children}</Heading>
	</Box>
);

function ErrorFooter() {
	return (
		<>
			{isWithoutLoginAgainButton() ? null : <SignInAgainButton />}
			<IncidentStatusLink />
			<ContactMessage incidentQuestion={true} />
		</>
	);
}

function IncidentStatusLink() {
	return (
		<Box mb="md">
			<Link to="https://status.fortnox.se/" variant="external">
				<span>{t`Se driftstatus på status.fortnox.se`}</span>
			</Link>
		</Box>
	);
}

function getTraceId() {
	return new URLSearchParams(window.location.search).get('traceId');
}

function isWithoutLoginAgainButton(): boolean {
	return new URLSearchParams(window.location.search).get('withoutLoginAgainButton') === 'true';
}

const ContactMessage = (props: { incidentQuestion?: boolean }) => {
	const traceId = getTraceId();
	const contactA = (
		<a href="https://www.fortnox.se/kontakt" className={styles.link} target="_blank" rel="noreferrer">
			fortnox.se/kontakt
		</a>
	);

	const strongOrgText = <strong key="strong-org">{t`organisationsnummer`}</strong>;
	const strongTraceId = traceId ? <strong key="strong-traceId">{traceId}</strong> : null;

	return (
		<Box display="flex" flexDirection="row" gap="md" alignItems="center">
			<AwesomeIcon name="headset" size="sm" color={COLORS.TextDark} />
			<Text size="small">
				{props.incidentQuestion ? t`Inga driftstörningar just nu?` + ' ' : null}
				{jt`Vi hjälper dig gärna på ${contactA}.`}
				<Box>
					{strongTraceId
						? jt`Ange ert ${strongOrgText} och följande behandlings-ID: ${strongTraceId} när du kontaktar oss, så kan vi hjälpa dig mycket snabbare.`
						: jt`Ange ert ${strongOrgText} när du kontaktar oss, så kan vi hjälpa dig mycket snabbare.`}
				</Box>
			</Text>
		</Box>
	);
};
