import React from 'react';
import { t } from 'ttag';
import { Container, Heading, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { Box } from 'components/Box';

export const InviteNotFoundScreen = () => {
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="xl">
				<Heading as="h2">{t`Vi kunde inte hitta din inbjudan`}</Heading>
			</Box>
			<Box bgColor="beige-light" p="lg" borderRadius="md">
				<Text>{t`Kontrollera att länken är korrekt. Tänk också på att om du fått flera inbjudningar så är det bara den senaste som gäller.`}</Text>
			</Box>
		</Container>
	);
};
