import React from 'react';
import { t } from 'ttag';
import { Heading, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../../components/Header';
import { Box } from 'components/Box';

export const LicenseNeededHeader = () => (
	<>
		<Header mb={{ base: 'md', sm: 'xl' }} />
		<Box mb="md">
			<Heading as="h1">{t`Licenser behöver tilldelas`}</Heading>
		</Box>
		<Box mb="xl">
			<Text>
				{t`Någon annan på ditt företag har redan uppgraderat kontot och tilldelats de licenser du använde tidigare. En systemadministratör behöver omfördela eller köpa de licenser som saknas.`}
			</Text>
		</Box>
	</>
);
