import React from 'react';
import { Container } from '@fnox/eternal-smooth-ui';
import { ContinueWithoutLicenses } from './components/ContinueWithoutLicenses';
import { LicenseNeededFooter } from './components/LicenseNeededFooter';
import { LicenseNeededHeader } from './components/LicenseNeededHeader';
import { Box } from 'components/Box';

export const LicenseNeededScreen = () => {
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<LicenseNeededHeader />
			<Box mb="xl">
				<ContinueWithoutLicenses resignSysAdmin={false} />
			</Box>
			<LicenseNeededFooter />
		</Container>
	);
};
