import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Spinner } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { useApiFetch } from '../hooks/useApi';
import { useTimer } from '../hooks/useTimeout';
import { TENANT_CONNECTED_PATH, TENANT_CONNECTED_SYS_ADMIN_PATH } from '../paths';
import { AskIfSysAdminScreen } from './AskIfSysAdminScreen';
import { LicenseNeededScreen } from './LicenseNeededScreen';
import { RemindFortnoxIdScreen } from './RemindFortnoxId';
import { TenantConnectedScreen } from './TenantConnectedScreen';
import { MigrationSession } from './types';
import { parseUtcDate } from 'common/date';
import { useServerTime } from 'components/TimeProvider';

export function MigrationScreen() {
	const [sessionRequest] = useApiFetch<MigrationSession>('ui-login-v1/fetch-migration-session');

	const serverDateString = sessionRequest.type === 'success' && sessionRequest.fetchResponse.headers.get('date');
	useServerTime(serverDateString);

	if (sessionRequest.requesting || sessionRequest.type === undefined) {
		return (
			<Container maxWidth="sm" centerHorizontally padding="md">
				<Header mb="xl" />
				<Spinner enabled />
			</Container>
		);
	}

	if (sessionRequest.type === 'failure') {
		return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
	}

	if (sessionRequest.type === 'error') {
		return <Redirect to={'/login-fortnox-id/error/' + sessionRequest.error.error} />;
	}

	return <MigrationContent migrationSession={sessionRequest.data} />;
}

function MigrationContent({ migrationSession }: { migrationSession: MigrationSession }) {
	const [expired, setExpired] = useState(false);

	useTimer(() => setExpired(true), parseUtcDate(migrationSession.expiresAt));

	if (expired) {
		return <Redirect to="/login-fortnox-id/migration/expired" />;
	}

	return (
		<Switch>
			<Route path="/login-fortnox-id/migration/remind-fortnox-id">
				<RemindFortnoxIdScreen session={migrationSession} />
			</Route>
			<Route path={TENANT_CONNECTED_PATH}>
				<TenantConnectedScreen session={migrationSession} continuation="normal" />
			</Route>
			<Route path={TENANT_CONNECTED_SYS_ADMIN_PATH}>
				<TenantConnectedScreen session={migrationSession} continuation="to-user-administration" />
			</Route>
			<Route path="/login-fortnox-id/migration/ask-if-sys-admin">
				<AskIfSysAdminScreen />
			</Route>
			<Route path="/login-fortnox-id/migration/license-needed">
				<LicenseNeededScreen />
			</Route>
		</Switch>
	);
}
