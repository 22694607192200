import { useEffect } from 'react';
import { frontendLog } from './frontendLog';

export function useUnhandledErrorLogger() {
	useEffect(() => {
		const errorHandler = (event: ErrorEvent) => {
			frontendLog({
				message: event.message,
				data: {
					unhandledError: event.error instanceof Error ? createLogError(event.error) : null,
				},
			});
		};

		const unhandledRejectionHandler = (ev: PromiseRejectionEvent) => {
			frontendLog({
				message: 'Unhandled rejection',
				data: {
					unhandledRejectionError: ev.reason instanceof Error ? createLogError(ev.reason) : null,
				},
			});
		};

		window.addEventListener('error', errorHandler);
		window.addEventListener('unhandledrejection', unhandledRejectionHandler);

		return () => {
			window.removeEventListener('error', errorHandler);
			window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
		};
	}, []);
}

function createLogError(error: Error): unknown {
	return {
		stack: error.stack,
		message: error.message,
		name: error.name,
	};
}
