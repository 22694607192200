/**
 * MAKE SURE IT'S LOADED BEFORE ALL OTHER IMPORTS!
 *
 * Frontend instrumentation:
 * - [Sentry](https://fortnox-ab.sentry.io/): observability and error reporting.
 */
import { getCookie } from './common/cookie';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://59dbdc18452ebabf32139a453eb5585f@o4507146791747584.ingest.de.sentry.io/4507451963932752',
	enabled: checkIfProduction(),
	release: `login-fortnox-id@${process.env.SENTRY_GIT_TAG}`,
});

Sentry.setTag('fortnox_fdid', getCookie('fdid'));

function checkIfProduction(): boolean {
	return !!window?.location?.hostname?.match(/apps[0-9]*.fortnox.se/g);
}
