import React, { useState } from 'react';
import cls from 'classnames';
import { t } from 'ttag';
import { AwesomeIcon, Text } from '@fnox/eternal-smooth-ui';
import { SupportButton } from '../../TenantSelect/SupportButton';
import { TenantSelectContext, UserInTenantSelectContext } from '../../TenantSelect/common';
import { locationAssign } from '../../common/redirect';
import { sendClickMetrics } from '../../hooks/useApi';
import { Box } from '../Box';
import styles from './Header.module.css';
import logo from './logo.svg';
import {
	faArrowRightFromBracket,
	faArrowUpRightFromSquare,
	faChevronDown,
	faChevronUp,
	faIdBadge,
} from '@fortawesome/pro-light-svg-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

function TenantSelectorUserMenu({ user }: { user: UserInTenantSelectContext }): React.ReactNode {
	const [isOpen, setOpen] = useState(false);
	const userName = `${user.givenName} ${user.familyName}`;
	const userInitials = `${user.givenName[0]}${user.familyName[0]}`;
	const manage = () => {
		sendClickMetrics('manage_fortnox_id');
		window.open('/api/login-fortnox-id/account', '_blank');
	};
	const logout = () => {
		locationAssign('/api/login-fortnox-id/web-logout-v1');
	};

	return (
		<DropdownMenu.Root onOpenChange={(openState) => setOpen(openState)}>
			<DropdownMenu.Trigger asChild>
				<button
					data-test-id="user-menu-trigger"
					className={cls(styles.userButton, isOpen ? styles.userButtonOpen : undefined)}
					translate="no"
				>
					<Box className={styles.userInitialsBubble}>{userInitials}</Box>
					<Box as="span" display={{ base: 'none', sm: 'inline' }} className={styles.userName}>
						{userName}
					</Box>
					<AwesomeIcon className={styles.buttonIcon} name={isOpen ? faChevronUp.iconName : faChevronDown.iconName} />
				</button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content align="end" className={styles.actionsMenu}>
					<DropdownMenu.Item className={styles.actionsMenuItem} onClick={manage} key="x">
						<AwesomeIcon name={faIdBadge.iconName} />
						<Box display="flex" flex={1} justifyContent="space-between" alignItems="center">
							<Text>{t`Hantera Fortnox ID`}</Text>
							<AwesomeIcon size="2xs" name={faArrowUpRightFromSquare.iconName} />
						</Box>
					</DropdownMenu.Item>
					<DropdownMenu.Item className={styles.actionsMenuItem} onClick={logout} key="y">
						<AwesomeIcon name={faArrowRightFromBracket.iconName} />
						<Text>{t`Logga ut`}</Text>
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
}

export const MainHeader = (props: { context: TenantSelectContext }) => (
	<Box
		display="flex"
		flexDirection="row"
		alignItems="center"
		justifyContent="space-between"
		gap="xl"
		style={{ width: '100%' }}
	>
		<img src={logo} width="96" alt="" />
		<Box display="flex" flexDirection="row" alignItems="center" gap="xl">
			<TenantSelectorUserMenu user={props.context.user} />
			<SupportButton />
		</Box>
	</Box>
);
export default MainHeader;
