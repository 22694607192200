import React, { useState } from 'react';
import { Container } from '@fnox/eternal-smooth-ui';
import { Box } from '../components/Box';
import { ConfirmSysAdmin } from './components/ConfirmSysAdmin';
import { ContinueWithoutLicenses } from './components/ContinueWithoutLicenses';
import { LicenseNeededFooter } from './components/LicenseNeededFooter';
import { LicenseNeededHeader } from './components/LicenseNeededHeader';

export const AskIfSysAdminScreen = () => {
	const [submitting, setSubmitting] = useState(false);
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<LicenseNeededHeader />
			<Box mb="md">
				<ConfirmSysAdmin disabled={submitting} onSubmitting={setSubmitting} />
			</Box>
			<Box mb="xl">
				<ContinueWithoutLicenses resignSysAdmin disabled={submitting} onSubmitting={setSubmitting} />
			</Box>
			<LicenseNeededFooter />
		</Container>
	);
};
