import { useEffect, useState } from 'react';
import { useGetNow } from 'components/TimeProvider';

export function useTimer(callback: () => void, expiresAt: number): void {
	const getNow = useGetNow();
	useEffect(() => {
		const until = expiresAt - getNow();
		const timerId = setTimeout(callback, until > 0 ? until : 0);
		return () => clearTimeout(timerId);
	}, [expiresAt, getNow, callback]);
}

export function useTimeout(callback: () => void, ms: number) {
	const getNow = useGetNow();
	const getExpireAtFromNow = () => getNow() + ms;
	const [expiresAt, setExpiresAt] = useState(getExpireAtFromNow());
	useTimer(callback, expiresAt);
	const reset = () => {
		setExpiresAt(getExpireAtFromNow());
	};

	return reset;
}

export function useToggleTimeout(ms: number) {
	const [expired, setExpired] = useState(false);
	const resetInnerTimeout = useTimeout(() => setExpired(true), ms);

	const reset = () => {
		setExpired(false);
		resetInnerTimeout();
	};

	return [expired, reset] as const;
}
