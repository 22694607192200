import React from 'react';
import classnames from 'classnames';
import { t } from 'ttag';
import { AwesomeIcon, IconTooltip } from '@fnox/eternal-smooth-ui';
import { Box } from '../components';
import styles from './TenantSelect.module.css';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export function UnreadMessagesCount(props: {
	numUnreadMessages?: number;
	isLoadingContext: boolean;
	canShowLoading: boolean;
}) {
	const state = calcState(props.numUnreadMessages, props.isLoadingContext, props.canShowLoading);
	if (!state) {
		return <div style={{ width: 36, height: 26 }}></div>;
	}

	return (
		<>
			<Box className={styles.infoIcon} display="flex">
				<IconTooltip
					aria-label={state?.toolTip}
					testID="unreadMessages-icon-tooltip"
					icon={
						<AwesomeIcon
							className={classnames({ [styles.infoLoading]: state.loading })}
							data-test-id="unreadMessages-icon"
							name={state.icon}
						/>
					}
					position="top"
					tabIndex={-1}
					tooltip={state.toolTip}
				/>

				{props.numUnreadMessages != undefined && props.numUnreadMessages > 0 && (
					<Box className={styles.infoCircle}>{props.numUnreadMessages}</Box>
				)}
			</Box>
		</>
	);
}

type UIState = {
	icon: IconName;
	toolTip: string;
	loading?: boolean;
};

function calcState(
	numUnreadMessages: number | undefined,
	isLoadingContext: boolean,
	canShowLoading: boolean
): UIState | null {
	if (numUnreadMessages !== undefined) {
		if (numUnreadMessages > 0) {
			return {
				icon: 'envelope',
				toolTip: t`Det finns olästa brev`,
			};
		}

		return null;
	}

	if (isLoadingContext && !canShowLoading) {
		return null;
	}

	if (isLoadingContext) {
		return {
			loading: true,
			icon: 'envelope',
			toolTip: t`Laddar olästa brev...`,
		};
	}

	// This is an error condition: not loading but no data though
	// many users do not know about unread messages (inbox) and thus we do not show any error
	// since it would be confusing to show a warning icon for something they do not know about
	return null;
}
