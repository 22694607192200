import React from 'react';
import { Redirect } from 'react-router';
import { jt, t } from 'ttag';
import { Container, Heading, Spinner, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { InviteInfo } from './types';
import { Box } from 'components/Box';
import { useApiFetch } from 'hooks/useApi';

export const InviteInvalidScreen = () => {
	const [inviteInfoRequest] = useApiFetch<InviteInfo>('ui-invite-v1/fetch-invite-info');

	if (inviteInfoRequest.requesting || inviteInfoRequest.type === undefined) {
		return (
			<Container maxWidth="sm" centerHorizontally padding="md">
				<Header mb="xl" />
				<Spinner enabled />
			</Container>
		);
	}

	if (inviteInfoRequest.type === 'failure') {
		return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
	}

	if (inviteInfoRequest.type === 'error') {
		return <Redirect to={'/login-fortnox-id/error/' + inviteInfoRequest.error.error} />;
	}

	const inviteInfo = inviteInfoRequest.data;

	const inviterText = <strong key="inviter">{inviteInfo.inviterName || t`din administratör`}</strong>;
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="xl">
				<Heading as="h2">{t`Din inbjudan gäller inte längre`}</Heading>
			</Box>
			<Box bgColor="beige-light" p="lg" borderRadius="md">
				<Text>
					{jt`En inbjudan behöver accepteras inom 2 veckor och kan bara accepteras en gång. Be ${inviterText} att skicka en ny inbjudan.`}
				</Text>
			</Box>
		</Container>
	);
};
