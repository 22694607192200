import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { AwesomeIconButton, COLORS, Drawer, Heading } from '@fnox/eternal-smooth-ui';
import { eventbus } from '@fnox/fabstracta-platform';
import style from './SupportDrawer.module.css';

export const SupportDrawer = () => {
	const [visible, setVisible] = useState(false);
	const [position, setPosition] = useState<'top' | 'bottom' | 'left' | 'right'>('right');
	const [helpDrawerInitialized, setHelpDrawerInitialized] = useState(false);
	const iframe = useRef<HTMLIFrameElement | null>(null);
	const [minSize, setMinSize] = useState<number>(440);

	useEffect(() => {
		const helpToggle = () => {
			setVisible((prevState) => !prevState);
		};

		const handleHelpCenterMessage = (event) => {
			if (event?.data?.type === 'help-center-set-drawer-minsize') {
				setMinSize(event?.data?.size);
			}
		};

		window.addEventListener('message', handleHelpCenterMessage);
		eventbus.on('application:help-toggle', helpToggle);
		return () => {
			window.removeEventListener('message', handleHelpCenterMessage);
			eventbus.off('application:help-toggle', helpToggle);
		};
	}, []);

	useEffect(() => {
		setHelpDrawerInitialized((prev) => prev || visible);
	}, [visible]);

	if (!helpDrawerInitialized) {
		return null;
	}

	return (
		<Drawer
			testID="support-drawer"
			onVisibleChange={setVisible}
			visible={visible}
			topOffset={92}
			bottomOffset={1}
			className={style.drawer}
			position={position}
			minSize={minSize}
			maxSize={0.95}
			header={
				<div className={style.drawerHeader}>
					<Heading as="h3">{t`Fortnox Support`}</Heading>
					<AwesomeIconButton name="xmark" onClick={() => setVisible(false)} aria-label={t`Stäng supportruta`} />
				</div>
			}
		>
			<div className={style.drawerContent}>
				<div className={style.iframeWrapper}>
					<iframe title="help-center" src="/uhelp" ref={iframe} data-fortnox-web-analytics="true" />
				</div>
				<div
					className={style.drawerFooter}
					style={{
						flexDirection: position == 'right' ? 'row' : 'row-reverse',
					}}
				>
					<AwesomeIconButton
						name={position == 'right' ? 'sidebar' : 'sidebar-flip'}
						color={COLORS.SupportFull}
						onClick={() => setPosition((oldVal) => (oldVal == 'right' ? 'left' : 'right'))}
						aria-label="sidebar-flip"
					/>
				</div>
			</div>
		</Drawer>
	);
};
