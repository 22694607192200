import React from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { t } from 'ttag';
import { Button, Container, Heading } from '@fnox/eternal-smooth-ui';
import { redirect } from '../common/redirect';
import { Header } from '../components/Header';
import { Box } from 'components/Box';

export const PasswordDisabledLoginScreen = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const continuePath = queryParams.get('continue');

	// Important to test that the "continue Path" is relative to our application
	// to prevent from open redirect attacks
	if (!continuePath || !continuePath.startsWith('/api/login-fortnox-id')) {
		console.error('Invalid continue path', continuePath);
		return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
	}

	const username = queryParams.get('username') ? ` ${queryParams.get('username')}` : '';
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="md">
				<Heading as="h1">{t`Hej!`}</Heading>
			</Box>
			<Box mb="md">
				<Heading as="h2">{t`Du kan inte längre logga in med ditt tidigare användarnamn ${username}.`}</Heading>
			</Box>
			<Box mb="xl">
				{t`Ditt Fortnox ID ersätter ditt tidigare användarnamn och med det så kan du välja att ange något av följande som användarnamn:`}
				<ul>
					<li>{t`Ditt personnummer (om du registrerat det)`}</li>
					<li>{t`Din e-postadress`}</li>
				</ul>
			</Box>
			<Box display="flex" flexDirection="column">
				<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
					<Button block onClick={() => redirect(continuePath, true)}>
						{t`Fortsätt till inloggning med Fortnox ID`}
					</Button>
				</Box>
			</Box>
		</Container>
	);
};
