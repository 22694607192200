import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button as EternalButton } from '@fnox/eternal-smooth-ui';
import { CommonButtonProps } from '@fnox/eternal-smooth-ui/dist/types/components/Button/internal';
import { funky, platform } from '@fnox/fabstracta';
import { networkTracker } from '@fnox/fabstracta-platform';

type PlainButtonUniqueProps = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> &
	Omit<CommonButtonProps, 'children'> & {
		loading?: boolean;
		children: React.ReactNode;
	};

/**
 * Button that uses a <button> element
 */
export const LoadingButton = forwardRef<HTMLButtonElement, PlainButtonUniqueProps>(({ loading, ...props }, ref) => {
	const dispatch = useDispatch();
	const [networkId] = useState(() => Math.random().toString());
	const networkStatus = platform.useFabstracta((state) => funky.get(state, `networkStatus`));

	useEffect(() => {
		if (loading) {
			let resolve: any;
			const promise = new Promise((promiseResolve) => (resolve = promiseResolve));
			dispatch(networkTracker(networkId, () => () => promise) as any);
			return () => resolve();
		}
	}, [loading, dispatch, networkId]);

	return <EternalButton {...(props as any)} isLoading={networkStatus[networkId]} ref={ref} shouldGiveFeedback />;
});
LoadingButton.displayName = 'Button';
