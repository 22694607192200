import React, { useState } from 'react';
import { jt, t } from 'ttag';
import { Container, Heading, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import confetti from '../images/confetti.svg';
import { MigrationSession } from './types';
import { Box } from 'components/Box';
import { LoadingButton } from 'components/LoadingButton';

type Continuation = 'normal' | 'to-user-administration';

export const TenantConnectedScreen = (props: { session: MigrationSession; continuation: Continuation }) => {
	const styledCompanyName = <strong key="company-name">{props.session.companyName}</strong>;
	const companyName = props.session.companyName;
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="md">
				<Heading as="h2">{t`${companyName} kopplat till ditt Fortnox ID`}</Heading>
			</Box>
			<Box mb="xl" textAlign="center">
				<img src={confetti} role="presentation" alt="" />
			</Box>
			<Box mb="xl">
				<Text>{jt`Nu kan du logga in i ${styledCompanyName} med ditt Fortnox ID.`}</Text>
			</Box>
			<Box display="flex" flexDirection="column">
				<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
					<ContinueButton continuation={props.continuation} />
				</Box>
			</Box>
		</Container>
	);
};

const ContinueButton = (props: { continuation: Continuation }) => {
	const [submitting, setSubmitting] = useState(false);

	const continuationApi =
		props.continuation === 'normal'
			? '/api/login-fortnox-id/web-login-v1/migration/tenant-connected-continue'
			: '/api/login-fortnox-id/web-login-v1/migration/tenant-connected-continue-sys-admin';

	return (
		<form method="post" action={continuationApi} onSubmit={() => setSubmitting(true)}>
			<LoadingButton loading={submitting} type="submit" block>
				{t`Fortsätt`}
			</LoadingButton>
		</form>
	);
};
