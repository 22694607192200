import React from 'react';
import { AwesomeIconButton } from '@fnox/eternal-smooth-ui';
import { eventbus } from '@fnox/fabstracta-platform';
import { track } from '../common/tracking';
import style from './supportButtton.module.css';

export function toggleSupportDrawer() {
	eventbus.emit('application:help-toggle');
	track(`support_drawer:toggle`);
}

export const SupportButton: React.FC<{ color?: 'black' | 'white' }> = ({ color }) => (
	<div className={style.supportButtonContainer}>
		<AwesomeIconButton
			name="circle-question"
			size="xs"
			onClick={toggleSupportDrawer}
			color={color}
			testID="supportButton"
			aria-label="support_button"
		/>
	</div>
);
