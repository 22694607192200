import React from 'react';
import { jt, t } from 'ttag';
import { Container, Heading, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { Box } from 'components/Box';

export const OnboardInviteInvalidScreen = () => {
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="xl">
				<Heading as="h2">{t`Din inbjudan gäller inte längre`}</Heading>
			</Box>
			<Box bgColor="beige-light" p="lg" borderRadius="md">
				<Text>{jt`En inbjudan behöver accepteras inom 4 veckor och kan bara accepteras en gång. Kontakta vår support för att få en ny inbjudan.`}</Text>
			</Box>
		</Container>
	);
};
