import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { Button, Heading } from '@fnox/eternal-smooth-ui';
import { platform } from '@fnox/fabstracta';
import { errorMessage } from '@fnox/fabstracta-platform';
import { useRedirect } from '../common/redirect';
import { Box, Stack } from '../components';
import Header from '../components/Header/Header';
import { TextInput } from '../components/TextInput';
import { useApi } from '../hooks/useApi';

type ActivateParams = {
	tenantId: number;
};

type ActivateResponse = {
	redirectUri: string;
};

function validateTenantId(tenantId: string) {
	if (platform.validateNumberField(tenantId) === true) {
		return true;
	}
	return t`Abonnemangsnumret ej giltigt`;
}

export function DemoUtbActivationContent() {
	const dispatch = useDispatch();
	const redirect = useRedirect();
	const [tenantId, setTenantId] = useState('');
	const [tenantErrorMessage, setTenantErrorMessage] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [, activate] = useApi<ActivateParams, ActivateResponse>('POST', 'ui-demo-utb-activation-v1/activate');

	async function onSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();

		if (validateTenantId(tenantId) !== true) {
			return;
		}

		setIsLoading(true);

		const numericTenantId = parseInt(tenantId);
		const result = await activate({ tenantId: numericTenantId });

		if (result.type === 'success') {
			redirect(result.data.redirectUri);
		} else {
			setIsLoading(false);
		}
		if (result.type === 'error') {
			if (result.error.error === 'invalid.tenant') {
				setTenantErrorMessage(t`Abonnemangsnumret ej giltigt`);
			} else if (result.error.error === 'tenant.already.mapped') {
				setTenantErrorMessage(t`Abonnemangsnumret är redan kopplat`);
			} else {
				console.error('Failed to activate tenant', result.error);
				dispatch(errorMessage('', t`Ett serverfel inträffade`));
			}
		}
	}

	return (
		<Stack gap="xl" style={{ maxWidth: '584px' }}>
			<Header />
			<Box>
				<Box mb="md">
					<Heading as="h1">{t`Kom igång med Fortnox`}</Heading>
				</Box>
				{t`Kom igång med ditt utbildnings- eller demo-företag genom att ange ditt abonnemangsnummer nedan.`}
			</Box>
			<form onSubmit={onSubmit}>
				<Box display="flex" flexDirection="column">
					<Box mb="xl">
						<TextInput
							value={tenantId}
							onChange={(e) => {
								setTenantErrorMessage(undefined);
								setTenantId(e.currentTarget.value);
							}}
							title={t`Abonnemangsnummer`}
							maxLength={64}
							validators={[() => validateTenantId(tenantId)]}
							explicitErrorMessage={tenantErrorMessage}
						/>
					</Box>
					<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
						<Button block type="submit" shouldGiveFeedback disabled={isLoading} isLoading={isLoading}>
							{t`Gå vidare`}
						</Button>
					</Box>
				</Box>
			</form>
		</Stack>
	);
}
