import React from 'react';
import { Box, BoxStyleProps } from '../Box';
import logo from './logo.svg';
import stripes from './stripes.svg';

export const Header = (props: BoxStyleProps) => {
	return (
		<Box mt={{ sm: '2xl' }} {...props}>
			<Box mb={{ base: 'md', sm: 'xl' }}>
				<img src={logo} width="96" alt="" />
			</Box>
			<img src={stripes} width="208" alt="" style={{ display: 'block' }} />
		</Box>
	);
};

export default Header;
