import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { t } from 'ttag';
import { Button, Container, Heading } from '@fnox/eternal-smooth-ui';
import { platform } from '@fnox/fabstracta';
import { track } from '../common/tracking';
import { Box } from '../components/Box';
import { Header } from '../components/Header';
import { LoadingButton } from '../components/LoadingButton';
import { TextInput } from '../components/TextInput';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { EmployeeOption } from './common';

const emailEntityKey = 'tenantSelect.employee.email';

export const TenantSelectEmployeeScreen = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	// const [employeeEmail, setEmployeeEmail] = useState(employee.email || '');
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [submitting, setSubmitting] = useState(false);
	const employee: EmployeeOption = useTypedSelector((state) => state.entities.tenantSelect?.employee);

	if (!employee) {
		return <Redirect to="/login-fortnox-id/tenant-select" />;
	}

	const companyDisplayName = employee.companyName ?? employee.tenantId;
	const validationError = errorMessage && !submitting ? errorMessage : undefined;

	function handleSubmit(ev) {
		if (
			platform.validateRequiredField(employee.email) !== true ||
			platform.validateEmailField(employee.email) !== true
		) {
			setErrorMessage(t`Du behöver ange en e-postadress`);
			ev.preventDefault();
			track(`select_employee_email_form:validation_error:submit`, 'email:invalid');
		}
	}

	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="xl">
				<Heading as="h1">{t`Skapa användarkonto till ${companyDisplayName}`}</Heading>
			</Box>
			<Box mb="sm">
				<Heading as="h3">{t`Ange en e-postadress till ditt konto`}</Heading>
			</Box>
			<Box mb="md">{t`För att kunna skapa en inloggning behöver ditt konto ha en e-postadress.`}</Box>
			<Box mb="md">
				<TextInput
					title={t`E-post`}
					autoFocus
					value={employee.email ?? ''}
					disabled={submitting}
					explicitErrorMessage={validationError}
					onChange={(e) => dispatch(platform.fieldChange(emailEntityKey, e.currentTarget.value))}
				/>
			</Box>
			<form
				method="post"
				action="/api/login-fortnox-id/web-login-v1/select-employee"
				onSubmit={() => setSubmitting(true)}
			>
				<input type="hidden" name="tenantId" value={employee.tenantId} />
				<input type="hidden" name="employeeId" value={employee.employeeId} />
				<input type="hidden" name="email" value={employee.email ?? ''} />
				<Box mb="xl">
					<LoadingButton block loading={submitting} onClick={handleSubmit} type="submit">{t`Fortsätt`}</LoadingButton>
				</Box>
			</form>
			<Box mb="xl">
				<hr />
			</Box>
			<Button secondary onClick={() => history.go(-1)}>{t`Avbryt`}</Button>
		</Container>
	);
};
