import { frontendLog } from '../ErrorBoundary/frontendLog';

export const errorLoggerCallback = (
	errorTitle: string,
	errorMessage: string,
	extraInfo = '',
	url = window.location.href
): void => {
	// If it's a cross-origin error, just resolve the promise, it's expected for this to happen
	if (isExcludedPattern(errorTitle)) return;

	const message = `Title: fortnoxWebAnalytics:${errorTitle} ErrorLog: ${errorMessage} ${
		extraInfo === '' ? '' : `ExtraInfo: ${extraInfo}`
	}`;

	const body = {
		message,
		url,
		type: 'javascript',
		data: {
			userAgent: window?.navigator?.userAgent,
			hasSendBeacon: 'sendBeacon' in navigator ? 'true' : 'false',
			frontendUrl: window.location.href,
		},
	};

	frontendLog(body);
};

export const isExcludedPattern = (errorTitle: string): boolean => {
	return /SecurityError|TypeError/i.test(errorTitle);
};
