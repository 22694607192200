import React from 'react';
import { t } from 'ttag';
import { Button } from '@fnox/eternal-smooth-ui';
import { useRedirect } from '../hooks/useRedirect';
import { Box } from './Box';

export const SignInAgainButton = () => {
	const redirect = useRedirect();
	return (
		<Box mt="xl" mb="xl">
			<Button onClick={() => redirect('/api/login-fortnox-id/web-login-v1')}>{t`Logga in igen`}</Button>
		</Box>
	);
};
