import React from 'react';
import cls from 'classnames';
import { Icon } from '@fnox/eternal-smooth-ui';
import { IconElement } from '@fnox/eternal-smooth-ui/dist/types/components/Icon/internal';
import styles from './Link.module.css';

type LinkInternal = {
	variant?: 'internal';
	/**
	 * Icon is never used with internal links
	 */
	icon?: never;
};
type LinkExternal = {
	variant?: 'external';
	/**
	 * Icon is never used with external links
	 */
	icon?: never;
};

type LinkButton = {
	variant?: 'button';
	/**
	 * An <Icon /> or <AwesomeIcon>
	 */
	icon: IconElement;
};

type LinkBackButton = {
	variant: 'backButton';
	/**
	 * An <AwesomeIcon> with the name 'arrow-left'
	 */
	icon: IconElement;
};

type LinkVessel = {
	/**
	 * Navigate to other vessel app using `navigate-v1` postMessage event.
	 *
	 * Vessel listens to these events and updates url of top iframe accordingly without a page reload.
	 *
	 * The route should start with a slash. For example `/fortnox-marketing-pages/kf`
	 */
	variant?: 'vessel';
	/**
	 * Icon is never used with external links
	 */
	icon?: never;
};

/**
 * Sets the variant of the button.
 */
type LinkVariantProps = LinkInternal | LinkExternal | LinkButton | LinkVessel | LinkBackButton;
type LinkSize = 'small' | 'medium';

type Props = {
	/**
	 * Sets the size of the link.
	 */
	size?: LinkSize;
	/**
	 * An id used by the test framework like Cypress and Detox (not Helium). Some component implementations have a default value for it already set up for convenience, e.g. the BackArrow component, but the default value can always be overridden by explicitly passing the prop to the given component.
	 */
	testID?: string;
	/**
	 * Conns the given component with a particular CSS class name for styling.
	 */
	className?: string;
	/**
	 * Sets the type of the Link. When used in a text, use type paragraph.
	 */
	type?: 'paragraph' | 'regular';
	/**
	 * Sets the route to which the user is directed to on click.
	 */
	to?: string;
	children?: React.ReactNode;
	/**
	 * Function that will be invoked when the link is clicked.
	 */
	onClick?: (event?: React.MouseEvent) => void;
	ping?: string;
};

export const Link: React.FC<Props & LinkVariantProps> = ({
	size = 'medium',
	children,
	to,
	className,
	testID,
	variant = 'internal',
	icon = '',
	type = 'regular',
	onClick,
	ping,
}) => {
	const isExternalLink = variant === 'external' || (to && to.startsWith('http'));
	const isInternalLink = variant === 'internal';
	const isVesselLink = variant === 'vessel';
	const isButton = variant === 'button';
	const isParagraph = type === 'paragraph';
	const isBackButton = variant === 'backButton';

	const classNames = cls(styles.link, className, {
		[styles.externalLink]: isExternalLink && !isParagraph && !isButton,
		[styles.internalLink]: (isVesselLink || isInternalLink) && !isParagraph && !isButton,
		[styles.buttonLink]: isButton,
		[styles.paragraph]: isParagraph,
		[styles.backButtonLink]: isBackButton,
		...(size === 'small' ? { [styles[size]]: size } : {}),
	});

	const additionalProps = ping ? { ping: ping } : {};

	return (
		<>
			{isExternalLink || isVesselLink ? (
				<a
					data-test-id={testID}
					onClick={onClick}
					href={to}
					className={classNames}
					target="_blank"
					rel="noreferrer"
					{...additionalProps}
				>
					{isButton && (
						<>
							{React.isValidElement(icon) &&
								React.cloneElement(icon as React.ReactElement, { className: styles.buttonIcon })}
						</>
					)}
					<span className={styles.text}>{children}</span>
					{!isParagraph && !isButton && !isVesselLink && (
						<Icon name="gotolink" size={size === 'small' ? 16 : 18} className={styles.icon} />
					)}
				</a>
			) : (
				<>
					<a onClick={onClick} href={to} data-test-id={testID} className={classNames}>
						{React.isValidElement(icon) &&
							React.cloneElement(icon as React.ReactElement, { className: styles.buttonIcon })}
						<span className={styles.text}>{children}</span>
					</a>
				</>
			)}
		</>
	);
};
