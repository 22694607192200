import React from 'react';
import { useLocale } from '../Localize';
import appStoreBadgeEn from '../images/app-store-badge-en.svg';
import appStoreBadgeSv from '../images/app-store-badge.svg';
import googlePlayBadgeEn from '../images/google-play-badge-en.png';
import googlePlayBadgeSv from '../images/google-play-badge.png';
import { Box, BoxStyleProps } from 'components/Box';

type DownloadAppProps = BoxStyleProps;

export const AppLinks = (props: DownloadAppProps) => {
	const locale = useLocale();

	const appStoreBadge = locale === 'en' ? appStoreBadgeEn : appStoreBadgeSv;
	const googlePlayBadge = locale === 'en' ? googlePlayBadgeEn : googlePlayBadgeSv;

	return (
		<Box display="flex" {...props}>
			<Box mr="xs">
				<a
					href="https://play.google.com/store/apps/details?id=se.fortnox.powerup.prod"
					target="_blank"
					rel="noreferrer"
				>
					<img src={googlePlayBadge} alt="Google play store" height="41px" />
				</a>
			</Box>
			<a href="https://itunes.apple.com/se/app/fortnox/id1350403648?mt=8" target="_blank" rel="noreferrer">
				<img src={appStoreBadge} alt="App store" />
			</a>
		</Box>
	);
};
