import React, { useState } from 'react';
import { t } from 'ttag';
import { Card, Heading } from '@fnox/eternal-smooth-ui';
import { Box } from '../../components/Box';
import { LoadingButton } from 'components/LoadingButton';

export const ConfirmSysAdmin = (props: { disabled: boolean; onSubmitting: (boolean) => void }) => (
	<Card>
		<Box mb="md">
			<Heading as="h3">{t`Jag är systemadministratör, jag löser det!`}</Heading>
		</Box>
		<Box mb="md">
			{t`Omfördela eller köp de licenser som behövs på din användare så att du kan fortsätta arbeta som vanligt.`}
		</Box>
		<Box display="flex" flexDirection="column">
			<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
				<AssignLicensesButton {...props} />
			</Box>
		</Box>
	</Card>
);

const AssignLicensesButton = (props: { disabled: boolean; onSubmitting: (boolean) => void }) => {
	const [submitting, setSubmitting] = useState(false);
	const handleSubmit = () => {
		setSubmitting(true);
		props.onSubmitting(true);
	};
	return (
		<form method="post" action="/api/login-fortnox-id/web-login-v1/migration/confirm-sys-admin" onSubmit={handleSubmit}>
			<LoadingButton secondary disabled={props.disabled} loading={submitting} type="submit" block>
				{t`Tilldela licenser`}
			</LoadingButton>
		</form>
	);
};
