import React from 'react';
import { AwesomeIcon, COLORS, Text } from '@fnox/eternal-smooth-ui';
import { Box } from '../components';
import styles from './TenantSelect.module.css';
import { TenantAction, TenantSelectOption } from './TenantSelectOption';
import { TenantUserOption } from './common';
import { SelectedTenantOption } from './useTenantSelector';

export type UserSelectOptionProps = {
	option: TenantUserOption;
	numTodos: number | undefined;
	numUnreadMessages: number | undefined;
	isLoadingContext: boolean;
	canShowLoading: boolean;
	onSelect: (selected: SelectedTenantOption) => void;
	disabled?: boolean;
	// To distinguish between tenant users on the same tenant, id can be shown
	identifierHint?: string;
	actions: TenantAction[];
};

export const UserSelectOption = (props: UserSelectOptionProps) => {
	const option = props.option;

	const selectOption: SelectedTenantOption = {
		type: 'USER',
		tenantId: option.tenantId,
		id: option.userId.toString(),
		companyName: option.companyName,
		pinned: option.pinned,
		note: option.note,
		isSystemAdmin: option.isSystemAdmin,
	};

	return (
		<TenantSelectOption
			option={selectOption}
			onClick={props.onSelect}
			devNotice={option.companyNameErrorMessage}
			actions={props.actions}
			numTodos={props.numTodos}
			numUnreadMessages={props.numUnreadMessages}
			canShowLoading={props.canShowLoading}
			isLoadingContext={props.isLoadingContext}
			isBureau={option.isBureau}
		>
			<TenantSelectOption.Header option={option} identifierHint={props.identifierHint} />
			{option.organizationNumber || option.note ? (
				<Box display="flex" gap="md" alignItems="center">
					{option.organizationNumber && (
						<Text size="small" color={COLORS.ReadOnly} className={styles.orgNumber}>
							{option.organizationNumber}
						</Text>
					)}
					{option.note && (
						<Box display="flex" gap="2xs" alignItems="center" overflow="hidden">
							<AwesomeIcon name="note" className={styles.icon14px} />
							<Box style={{ marginTop: 2 }} overflow="hidden">
								<Text size="small" className="truncate">
									{option.note}
								</Text>
							</Box>
						</Box>
					)}
				</Box>
			) : null}
			<TenantSelectOption.Labels option={option} />
		</TenantSelectOption>
	);
};
