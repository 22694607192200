import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { t } from 'ttag';
import { Card, Heading } from '@fnox/eternal-smooth-ui';
import { Box } from '../../components/Box';
import { useApi } from '../../hooks/useApi';
import { useRedirect } from '../../hooks/useRedirect';
import { TENANT_CONNECTED_PATH } from '../../paths';
import { LoadingButton } from 'components/LoadingButton';

export const ContinueWithoutLicenses = (props: {
	resignSysAdmin: boolean;
	disabled?: boolean;
	onSubmitting?: (boolean) => void;
}) => (
	<Card>
		<Box mb="md">
			<Heading as="h3">{t`Min systemadministratör löser det`}</Heading>
		</Box>
		<Box mb="xl">
			{t`Den som är systemadministratör kommer bli meddelad så du kan få de licenser du behöver för att fortsätta arbeta som vanligt.`}
		</Box>
		<Box display="flex" flexDirection="column">
			<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
				<ContinueButton {...props} />
			</Box>
		</Box>
	</Card>
);

type LicenseNeededContinueResponse =
	| { type: 'ShowTenantConnectedResponse' }
	| { type: 'LoginResponse'; redirectUri: string };

const ContinueButton = (props: { resignSysAdmin: boolean; disabled?: boolean; onSubmitting?: (boolean) => void }) => {
	const [submitting, setSubmitting] = useState(false);
	const redirect = useRedirect();
	const history = useHistory();

	const [, licenseNeededContinue] = useApi<void, LicenseNeededContinueResponse>(
		'GET',
		'ui-login-v1/migration/license-needed-continue?resignSysAdmin=' + props.resignSysAdmin
	);

	const handleClick = async () => {
		props.onSubmitting?.(true);
		setSubmitting(true);
		const result = await licenseNeededContinue();
		if (result.type === 'success') {
			const data = result.data;
			if (data.type == 'ShowTenantConnectedResponse') {
				history.push(TENANT_CONNECTED_PATH);
			} else {
				redirect(data.redirectUri);
			}
		} else {
			props.onSubmitting?.(false);
			setSubmitting(false);
		}
	};

	return (
		<LoadingButton secondary disabled={props.disabled} loading={submitting} onClick={handleClick} type="button" block>
			{t`Logga in och vänta på licenser`}
		</LoadingButton>
	);
};
