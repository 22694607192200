import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { InviteAcceptedIndex } from './Accepted';
import { InviteInactiveScreen } from './Inactive';
import { InviteInvalidScreen } from './Invalid';
import { InviteNotFoundScreen } from './NotFound';
import { UnexpectedAuthResponseInviteScreen } from './UnexpectedAuthResponse';

export function InviteRoutes() {
	return (
		<Switch>
			<Route path="/login-fortnox-id/invite/invalid">
				<InviteInvalidScreen />
			</Route>
			<Route path="/login-fortnox-id/invite/inactive">
				<InviteInactiveScreen />
			</Route>
			<Route path="/login-fortnox-id/invite/not-found">
				<InviteNotFoundScreen />
			</Route>
			<Route path="/login-fortnox-id/invite/unexpected-auth-response">
				<UnexpectedAuthResponseInviteScreen />
			</Route>
			<Route path="/login-fortnox-id/invite/accepted*">
				<InviteAcceptedIndex />
			</Route>
		</Switch>
	);
}
