import React from 'react';
import { Box } from './Box';
import { Sprinkles, sprinkles } from './Box/sprinkles.css';

export function BorderBox({ children, ...sprinkleProps }: { children: React.ReactNode } & Sprinkles) {
	return (
		<Box
			border="line-color"
			borderRadius="lg"
			display="flex"
			alignItems="center"
			justifyContent="center"
			p="md"
			gap="sm"
			flexDirection="column"
			textAlign="center"
			className={sprinkles(sprinkleProps)}
		>
			{children}
		</Box>
	);
}
