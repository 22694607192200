const getEnvironment = (hostname = window.location.hostname) => {
	if (hostname.endsWith('dev.fnox.se') || hostname.endsWith('alfa.fnox.se')) {
		return 'dev';
	}

	if (hostname.endsWith('.fortnox.se')) {
		if (hostname.includes('-acce-')) {
			return 'acce';
		}
		return 'prod';
	}

	return 'local';
};

type Settings = typeof prod;

const prod = {
	piwikPro: {
		applicationId: '8c5cd131-8d36-48dd-9d33-9b3802d223fc',
		cookieDomain: '.fortnox.se',
		dimensions: {
			loggedIn: 'dimension16',
			clientUserId: 'dimension28',
			clientTenantId: 'dimension27',
			parentUserId: 'dimension30',
			parentTenantId: 'dimension29',
			isConsultant: 'dimension31',
			isFinanceClient: 'dimension34',
			customerType: 'dimension37',
			isSystemAdministrator: 'dimension35',
			component: 'dimension17',
			discoverFortnoxTipsId: 'dimension24',
			discoverFortnoxDialogFlow: 'dimension26',
			clickElementText: 'dimension21',
			platform: 'dimension41',
			sessionConsentStatus: 'dimension40',
			eventConsentStatus: 'dimension39',
			customTimestamp: 'dimension36',
			articleId: 'dimension43',
			articleName: 'dimension44',
		},
	},
};

const acce: Settings = {
	piwikPro: {
		applicationId: 'd3954971-579d-43ba-9edc-6a7578bd1f53',
		cookieDomain: '.fnox.se',
		dimensions: {
			loggedIn: 'dimension24',
			clientUserId: 'dimension28',
			clientTenantId: 'dimension27',
			parentUserId: 'dimension30',
			parentTenantId: 'dimension29',
			isConsultant: 'dimension31',
			isFinanceClient: 'dimension32',
			customerType: 'dimension33',
			isSystemAdministrator: 'dimension34',
			component: 'dimension25',
			discoverFortnoxTipsId: 'dimension23',
			discoverFortnoxDialogFlow: 'dimension26',
			clickElementText: 'dimension15',
			platform: 'dimension35',
			sessionConsentStatus: 'dimension36',
			eventConsentStatus: 'dimension37',
			customTimestamp: 'dimension16',
			articleId: 'dimension41',
			articleName: 'dimension42',
		},
	},
};

const dev: Settings = {
	piwikPro: {
		applicationId: 'd3954971-579d-43ba-9edc-6a7578bd1f53',
		cookieDomain: '.fnox.se',
		dimensions: {
			loggedIn: 'dimension24',
			clientUserId: 'dimension28',
			clientTenantId: 'dimension27',
			parentUserId: 'dimension30',
			parentTenantId: 'dimension29',
			isConsultant: 'dimension31',
			isFinanceClient: 'dimension32',
			customerType: 'dimension33',
			isSystemAdministrator: 'dimension34',
			component: 'dimension25',
			discoverFortnoxTipsId: 'dimension23',
			discoverFortnoxDialogFlow: 'dimension26',
			clickElementText: 'dimension15',
			platform: 'dimension35',
			sessionConsentStatus: 'dimension36',
			eventConsentStatus: 'dimension37',
			customTimestamp: 'dimension16',
			articleId: 'dimension41',
			articleName: 'dimension42',
		},
	},
};

const local: Settings = {
	piwikPro: {
		applicationId: '12d804dd-5d11-43ce-8dae-d0c32329bb18',
		cookieDomain: 'localhost',
		dimensions: {
			loggedIn: 'dimension9',
			clientUserId: 'dimension14',
			clientTenantId: 'dimension15',
			parentUserId: 'dimension16',
			parentTenantId: 'dimension17',
			isConsultant: 'dimension19',
			isFinanceClient: 'dimension18',
			customerType: 'dimension20',
			isSystemAdministrator: 'dimension21',
			component: 'dimension6',
			discoverFortnoxTipsId: 'dimension10',
			discoverFortnoxDialogFlow: 'dimension13',
			clickElementText: 'dimension15',
			platform: 'dimension22',
			sessionConsentStatus: 'dimension24',
			eventConsentStatus: 'dimension23',
			customTimestamp: 'dimension12',
			articleId: 'dimension25',
			articleName: 'dimension26',
		},
	},
};

const settingsByEnvironment = {
	prod: prod,
	acce: acce,
	dev: dev,
	local: local,
};

export const settings = settingsByEnvironment[getEnvironment()] ?? local;
