import React from 'react';
import { t } from 'ttag';
import { Container, Heading, Link, Text } from '@fnox/eternal-smooth-ui';
import { AppLinks } from '../components/AppLinks';
import { Header } from '../components/Header';
import welcomeAppSrc from '../images/welcome_app.jpg';
import styles from './DownloadApp.module.css';
import { Box } from 'components/Box';

type DownloadAppInviteAcceptedScreenProps = {
	onNext: () => void;
};

export const DownloadAppInviteAcceptedScreen = (props: DownloadAppInviteAcceptedScreenProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<Container maxWidth="sm" centerHorizontally padding="md">
					<Header mb="xl" />
					<Box mb="md">
						<Heading as="h1">{t`Kom igång med Fortnox`}</Heading>
					</Box>
					<Box mb="xl">
						<Text as="p">{t`Enklaste sättet att samarbeta i Fortnox är att ladda upp kvitton och underlag i vår app. Där kan du också svara på meddelanden från dina medarbetare i Fortnox.`}</Text>
						<Text>{t`Du kan också logga in med BankID eller Fortnox ID i vår webbplats på dator eller surfplatta på fortnox.se.`}</Text>
					</Box>
					<Box mb="md">
						<Heading as="h3">{t`Ladda ner Fortnox app`}</Heading>
					</Box>
					<AppLinks mb="xl" />
					<Box textAlign="right">
						<Link onClick={props.onNext} to="#continue">{t`Fortsätt till Fortnox`}</Link>
					</Box>
				</Container>
			</div>
			<div
				className={styles.image}
				style={{
					backgroundImage: `url(${welcomeAppSrc})`,
				}}
			/>
		</div>
	);
};
