import React, { useState } from 'react';
import { jt, t } from 'ttag';
import { Alert, Container, Heading } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { MigrationSession } from './types';
import { Box } from 'components/Box';
import { LoadingButton } from 'components/LoadingButton';

export const RemindFortnoxIdScreen = (props: { session: MigrationSession }) => {
	const username = props.session.tenantUsername;
	const login = (
		<strong key="loginText">
			{props.session.unverifiedPersonalNumber || props.session.personalNumber
				? t`Logga in med BankID, ditt personnummer eller e-postadress`
				: t`Logga in med din e-postadress`}
		</strong>
	);
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="md">
				<Heading as="h1">{t`Påminnelse: Ändring av användarnamn`}</Heading>
			</Box>
			<Box mb="xl">
				<Alert type="warning">
					{jt`Ditt tidigare användarnamn ${username} kan inom kort inte längre användas för att logga in. ${login} och lösenord, från och med nu.`}
				</Alert>
			</Box>
			<Box display="flex" flexDirection="column">
				<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
					<ContinueButton />
				</Box>
			</Box>
		</Container>
	);
};

const ContinueButton = () => {
	const [submitting, setSubmitting] = useState(false);

	return (
		<form
			method="post"
			action="/api/login-fortnox-id/web-login-v1/migration/remind-fortnox-id-continue"
			onSubmit={() => setSubmitting(true)}
		>
			<LoadingButton loading={submitting} type="submit" block>
				{t`Fortsätt`}
			</LoadingButton>
		</form>
	);
};
