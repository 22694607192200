import React from 'react';
import { t } from 'ttag';
import { Button, Heading, Text } from '@fnox/eternal-smooth-ui';
import { Box } from '../components/Box';
import { Stack } from '../components/Stack';
import { TenantUserOption } from './common';

type UserInfoDialogContentProps = {
	user: TenantUserOption;
	close: () => void;
};

export const UserInfoDialogContent = (props: UserInfoDialogContentProps) => {
	return (
		<>
			<Stack gap="md" mb="xl">
				<Heading as="h2">{t`Om användaren`}</Heading>
				{props.user.organizationNumber && (
					<div>
						{t`Organisationsnummer`}
						<Text weight="bold">{props.user.organizationNumber}</Text>
					</div>
				)}
				<div>
					{t`Abonnemangsnummer`}
					<Text weight="bold">{props.user.tenantId}</Text>
				</div>
				<div>
					{t`Användar-ID`}
					<Text weight="bold">{props.user.userId}</Text>
				</div>
				{props.user.employeeId && (
					<div>
						{t`Anställningsnummer`}
						<Text weight="bold">{props.user.employeeId}</Text>
					</div>
				)}
			</Stack>
			<Box display="flex" justifyContent="flex-end" gap="md">
				<Button secondary onClick={props.close}>
					{t`Stäng`}
				</Button>
			</Box>
		</>
	);
};
