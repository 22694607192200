import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TAnalyticsWindowObject, initWebAnalytics, trackPageView } from '@fnox/web-analytics-script';
import { requestApi } from '../../hooks/useApi';
import { frontendLog } from '../ErrorBoundary/frontendLog';
import { settings } from './environment';
import { errorLoggerCallback } from './fortnoxWebAnalyticsErrorLogger';

declare global {
	interface Window {
		fortnoxWebAnalyticsDebug?: boolean;
		fortnoxWebAnalyticsLoaded?: boolean;
		fortnoxWebAnalytics?: TAnalyticsWindowObject;
	}
}

type WebAnalyticsResponse = {
	idp: string;
	idpUserId: string;
};

let webAnalyticsScriptLoaded = false;

const setWebAnalyticsScriptLoaded = (value: boolean): void => {
	webAnalyticsScriptLoaded = value;
};

// read utm parameters on initial page load
const utmParameters = getUtmParameters();

export const FortnoxWebAnalytics = () => {
	const location = useLocation();

	useEffect(() => {
		if (webAnalyticsScriptLoaded) {
			console.debug('FortnoxWebAnalytics already loaded');
			return;
		}

		if (window.fortnoxWebAnalytics) window.fortnoxWebAnalytics = {};

		(async () => {
			const result = await requestApi<WebAnalyticsResponse>('GET', 'ui-web-analytics', undefined);

			const userId = result.type === 'success' ? result.data.idpUserId : null;

			initWebAnalytics({
				userId,
				washViewCallback,
				errorLoggerCallback,
				applicationId: settings.piwikPro.applicationId,
				cookieDomain: settings.piwikPro.cookieDomain,
				includeSubIFrames: true,
				piwik: {
					translateCustomDimensionKeyCallback: (key) => settings.piwikPro.dimensions[key] || null,
				},
			});

			setWebAnalyticsScriptLoaded(true);
		})();
	}, []);

	useEffect(() => {
		// The web-analytics-script always adds the current url as view.
		trackPageView({
			data: {
				loggedIn: 'true',
				component: 'Fortnox ID',
			},
		});
	}, [location.pathname]);

	return null;
};

const washHostname = (hostname: string) => {
	return hostname.replace(/^apps\d+\.fortnox\.se/, 'apps.fortnox.se');
};

export const washViewCallback = (view: string): string => {
	const url = new URL(view);
	const protocol = url.protocol;
	const hostname = washHostname(url.hostname);
	const port = url.port ? `:${url.port}` : ''; // Only add port if it exists
	const fullHostname = `${protocol}//${hostname}${port}`;

	const washedUrl = fullHostname + url.pathname;

	if (utmParameters && utmParameters.size > 0) {
		return `${washedUrl}?${utmParameters.toString()}`;
	}

	return washedUrl;
};

function getUtmParameters() {
	try {
		const url = new URL(document.location.toString());

		const params = new URLSearchParams();
		for (const [key, value] of url.searchParams) {
			if (key.startsWith('utm_')) {
				params.append(key, value);
			}
		}

		return params;
	} catch (e) {
		console.error('Failed to get utm parameters', e);
		frontendLog({
			message: 'Failed to get utm parameters',
			url: window.location.href,
			data: {
				error: createError(e),
			},
		});
		return null;
	}
}

function createError(e: any) {
	if (e instanceof Error) {
		return {
			stack: e.stack,
			message: e.message,
			name: e.name,
		};
	}
	return {
		message: e.toString(),
	};
}
