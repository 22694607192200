import { platform } from '@fnox/fabstracta';

export type FrontendLogOptions = {
	message: string;
	url?: string;
	data?: Record<string, unknown>;
};

export const frontendLog = async ({ message, url = window.location.href, data = {} }: FrontendLogOptions) => {
	if (process.env.NODE_ENV !== 'production') {
		return;
	}

	const errorLoggerLogUrl = `${platform.getBaseURL()}/api/errorlogger/log`;

	const payload = {
		message,
		service: 'fortnox-id',
		dbid: '0',
		url,
		type: 'javascript',
		data: {
			userAgent: window?.navigator?.userAgent,
			frontendUrl: window.location.href,
			...data,
		},
	};

	try {
		if ('sendBeacon' in navigator) {
			navigator.sendBeacon(errorLoggerLogUrl, JSON.stringify(payload));
		} else {
			return await fetch(errorLoggerLogUrl, {
				method: 'POST',
				mode: 'cors',
				body: JSON.stringify(payload),
				credentials: 'include',
			});
		}
	} catch (e) {
		console.warn('Failed to report frontend error', e);
	}
};
