import { trackCustomEvent } from '@fnox/web-analytics-script';

export function track(action: string, name?: string) {
	const event = {
		eventCategory: 'login_fortnox_id',
		eventAction: action,
		eventName: name,
	};
	trackCustomEvent(event);

	if (document.cookie.includes('fortnoxWebAnalyticsDebug=true')) {
		console.log(`customPiwikEvent: `, event);
	}
}
