import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { Button, COLORS, Dialog, Heading, Text } from '@fnox/eternal-smooth-ui';
import { funky, platform } from '@fnox/fabstracta';
import { Box, Chip } from '../components';
import { NotCopiedIdentityServiceMapping } from './common';

export const NotCopiedIdentityServiceMappingsDialog = (props: {
	invalidMappings: NotCopiedIdentityServiceMapping[];
}) => {
	const dispatch = useDispatch();
	const DIALOG_NAME = 'notCopiedIdentityServiceMappingsDialog';
	const isOpen = platform.useFabstracta((state) => funky.get(state, `modals.${DIALOG_NAME}`) || false);
	const handleClose = () => dispatch(platform.toggleModalHide(DIALOG_NAME));

	return (
		<Dialog open={isOpen} className="max-w-640" onClose={handleClose} showCloseIcon>
			<Box mb="md">
				<Heading as="h2">{t`Ej kopplade företag`}</Heading>
			</Box>
			<Box mb="md">{t`När vi inte kan koppla företag beror det oftast på att något annat Fortnox ID redan är kopplat till användaren där ditt personnummer är registrerat.`}</Box>
			<Box mb="md">{t`Be din systemadministratör hjälpa dig och logga sedan in igen.`}</Box>

			<Box mb="xl" gap="xs" display="flex" flexDirection="column">
				{props.invalidMappings.map((mapping, index) => (
					<Box display="flex" alignItems="center" key={index} border="thin" p="sm" px="md" borderRadius="md" gap="md">
						<Chip variant="error" size="large">{t`Kan inte kopplas`}</Chip>
						<Box flex={1}>
							<Text weight="bold">{mapping.companyName}</Text>
							<Text size="small">{mapping.tenantUserName}</Text>
						</Box>
						<Text size="small" color={COLORS.ReadOnly}>
							ID: {mapping.tenantUserId}
						</Text>
					</Box>
				))}
			</Box>

			<Box display="flex" justifyContent="flex-end">
				<Button
					onClick={() => dispatch(platform.toggleModalHide('notCopiedIdentityServiceMappingsDialog'))}
				>{t`Jag förstår`}</Button>
			</Box>
		</Dialog>
	);
};
