export interface UserInTenantSelectContext {
	givenName: string;
	familyName: string;
}

export type TenantSorting = 'ALPHABETICAL' | 'LAST_USED';

export interface ContextUserPreferences {
	companyEngagementsCollapsed?: boolean | null;
	tenantSorting?: TenantSorting | null;
}

export interface TenantSelectContext {
	type: 'CONTEXT';
	options: LoginOption[];
	expiresAt: string;
	incompleteOptionsList?: boolean | null;
	incompleteOptionsDetails?: boolean | null;
	loggedInViaBankId?: boolean;
	notCopiedIdentityServiceMappings?: NotCopiedIdentityServiceMapping[];
	user: UserInTenantSelectContext;
	userPreferences: ContextUserPreferences;
}

export interface TenantSelectContextTodosCount {
	type: 'TODOS_COUNT';
	tenantId: number;
	userId: number;
	todosCount: number;
}

export interface TenantSelectUnreadMessagesCount {
	type: 'UNREAD_MESSAGES_COUNT';
	count: number;
	tenantId: number;
	userId: number;
}

export type ResponseTenantSelectContext =
	| TenantSelectContext
	| TenantSelectContextTodosCount
	| TenantSelectUnreadMessagesCount;

export interface NotCopiedIdentityServiceMapping {
	companyName: string;
	tenantUserId: number;
	tenantUserName: string;
}

export interface BaseOption {
	tenantId: number;
	type: 'USER' | 'EMPLOYEE';
	companyName: string | null;
	// Cause for this error to be set today is when there's a mismatching f3 version (see TenantOptionsResolver.java)
	companyNameErrorMessage: string | null;
	companyInformationError?: string | null;
	organizationNumber: string | null;
	isBureau: boolean;
	isCompanyManager: boolean;
	isDeveloper: boolean;
}

export interface TenantUserOption extends BaseOption {
	type: 'USER';
	userId: number;
	employeeId: string;
	name: string;
	login: string;
	pinned: boolean;
	note?: string | null;
	isSystemAdmin?: boolean;
	lastUsed?: string;
}

export interface EmployeeOption extends BaseOption {
	type: 'EMPLOYEE';
	employeeId: string;
	email: string | null;
}

export type LoginOption = TenantUserOption | EmployeeOption;

function isPinned(l: TenantUserOption | EmployeeOption) {
	return l.type === 'USER' && l.pinned;
}

export function isTenantUserOption(option: BaseOption): option is TenantUserOption {
	return option.type === 'USER';
}

function getLastUsedTime(o: LoginOption) {
	return new Date(o.type == 'USER' ? o.lastUsed ?? 0 : 0).getTime();
}

export function sortTenantOptions(options: LoginOption[], sorting: TenantSorting) {
	return options.concat([]).sort((l, r) => {
		if (isPinned(l) && !isPinned(r)) {
			return -1;
		} else if (!isPinned(l) && isPinned(r)) {
			return 1;
		}

		if (sorting == 'LAST_USED') {
			const lastUsedComparison = getLastUsedTime(r) - getLastUsedTime(l);
			if (lastUsedComparison !== 0) {
				return lastUsedComparison;
			}
		}

		const companyNameComparison = (l.companyName ?? l.tenantId.toString()).localeCompare(
			r.companyName ?? r.tenantId.toString()
		);
		if (companyNameComparison !== 0) {
			return companyNameComparison;
		}

		const companyNoComparison = (l.organizationNumber ?? '').localeCompare(r.organizationNumber ?? '');
		if (companyNoComparison !== 0) {
			return companyNoComparison;
		}

		// Lower numbers first
		const tenantIdComparison = l.tenantId - r.tenantId;
		if (tenantIdComparison !== 0) {
			return tenantIdComparison;
		}

		if (l.type == 'USER' && r.type == 'USER') {
			// Lower numbers first
			return l.userId - r.userId;
		}

		if (l.type == 'USER' && r.type == 'EMPLOYEE') {
			return -1;
		}

		if (l.type == 'EMPLOYEE' && r.type == 'USER') {
			return 1;
		}

		return l.employeeId.localeCompare(r.employeeId);
	});
}

export function metricsPingURI(key: string) {
	return `/api/login-fortnox-id/ui-login-v1/metrics/${key}`;
}
