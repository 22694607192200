export const UI_TESTS_DELAY = 250;

export function useRedirect() {
	return redirect;
}

function redirect(uri: string, replace = false) {
	const exec = replace ? () => window.location.replace(uri) : () => window.location.assign(uri);
	if (navigator.webdriver) {
		setTimeout(() => {
			exec();
		}, UI_TESTS_DELAY);
	} else {
		exec();
	}
}
