import React, { useEffect, useState } from 'react';
import { Spinner } from '@fnox/eternal-smooth-ui';
import { delay } from 'common/delay';

export const DelayedSpinner = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		let cancelled = false;
		delay(500).then(() => {
			if (!cancelled) {
				setShow(true);
			}
		});

		return () => {
			cancelled = true;
		};
	}, []);

	if (!show) {
		return null;
	}

	return <Spinner enabled />;
};
