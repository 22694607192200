import { platform } from '@fnox/fabstracta';
import { ACTIONS } from '../actions/definitions';
import { rootReducer } from './rootReducer';
import { configureStore as configureStoreToolkit } from '@reduxjs/toolkit';

export const configureStore = () =>
	configureStoreToolkit({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => {
			const middleware = getDefaultMiddleware();

			if (process.env.NODE_ENV !== 'production') {
				middleware.push(platform.actionValidator(ACTIONS));
				/* eslint-disable @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports */
				middleware.push(require('redux-logger').logger);
			}

			return middleware;
		},
	});

export const store = configureStore();
