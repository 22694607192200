import React from 'react';
import { t } from 'ttag';
import { Heading, Text } from '@fnox/eternal-smooth-ui';
import { Box } from '../../components/Box';

export const LicenseNeededFooter = () => (
	<>
		<Box mb="md">
			<Heading as="h4">{t`Vad är en systemadministratör?`}</Heading>
		</Box>
		<Text>
			{t`Den som är systemadministratör på ditt företag är kontaktperson till Fortnox i alla frågor som rör tjänsterna och avtalet med Fortnox.`}
		</Text>
	</>
);
