import { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { errorMessage } from '@fnox/fabstracta-platform';
import { startAsyncParseJsonArrayFromReader } from './jsonStream';
import { delay } from 'common/delay';
import { isUiTestRunning } from 'common/test';

type HttpVerb = 'PUT' | 'POST' | 'GET' | 'DELETE';

export type RequestFailure = 'network-error' | 'server-error';

export type ValidationErrorCode =
	| 'validation.unique'
	| 'validation.invalid'
	| 'validation.expired_code'
	| 'validation.too_many_attempts'
	| 'validation.too_many_codes_sent'
	| 'validation.email'
	| null;

export interface ApiFieldError {
	error: ValidationErrorCode;
	field: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errorParams: { [key: string]: any };
}

export type ApiError = {
	error: string;
	errorParams?: Array<{ [key: string]: any }>;
	fields: Array<ApiFieldError>;
};

interface RequestApiErrorResult {
	type: 'error';
	error: ApiError;
}

const RequestFailureMessages: {
	[Key in RequestFailure]: string;
} = {
	'server-error': t`Ett serverfel inträffade`,
	'network-error': t`Ett nätverksfel inträffade`,
};

interface RequestApiFailureResult {
	type: 'failure';
	failure: RequestFailure;
}

interface RequestApiOkResult<TData> {
	type: 'success';
	data: TData;
	fetchResponse: Response;
}

export type RequestApiResult<TData> = RequestApiErrorResult | RequestApiOkResult<TData> | RequestApiFailureResult;

export function sendClickMetrics(key: string) {
	void requestApi('POST', `ui-login-v1/metrics/${key}`, null).finally();
}

export function sendVisitMetrics(key: string) {
	void requestApi('POST', `ui-login-v1/visit-metrics/${key}`, null).finally();
}

export const requestApi = async <T>(verb: HttpVerb, path: string, data: unknown): Promise<RequestApiResult<T>> => {
	try {
		const response = await fetch(`/api/login-fortnox-id/${path}`, {
			method: verb,
			body: verb !== 'GET' ? JSON.stringify(data) : undefined,
			headers: {
				'X-Requested-With': 'login-fortnox-id-ui',
			},
		});

		if (response.status >= 500) {
			console.error('API request server error response', verb, path, response.status, response.statusText);

			return {
				type: 'failure',
				failure: 'server-error',
			};
		}

		if (!response.ok) {
			return { error: await response.json(), type: 'error' };
		}

		if (response.status === 204) {
			return { data: {} as T, type: 'success', fetchResponse: response };
		}

		return { data: await response.json(), type: 'success', fetchResponse: response };
	} catch (e) {
		console.error('API request exception', verb, path, e);
		return {
			type: 'failure',
			failure: 'network-error',
		};
	}
};

function useDispatchToast() {
	return useDispatch();
}

type UseApiState<TResult> = {
	requesting: boolean;
} & (RequestApiResult<TResult> | { type?: undefined });

type UseApiReturnValue<TResult, TParams> = [
	UseApiState<TResult>,
	(args: TParams) => Promise<RequestApiResult<TResult>>,
	(optimisticallyDataProvider: (currentData: TResult) => TResult) => void,
];

export function useApi<TParams, TResult = unknown>(
	verb: HttpVerb,
	partialPath: string,
	options?: {
		minDelay?: number | true;
	}
): UseApiReturnValue<TResult, TParams> {
	const dispatchToast = useDispatchToast();
	const [state, setState] = useState<UseApiState<TResult>>({
		requesting: false,
	});

	const request = useCallback(
		async (params: TParams) => {
			setState({ requesting: true });

			const minDelayPromise =
				options?.minDelay && !isUiTestRunning()
					? delay(options.minDelay === true ? undefined : options.minDelay)
					: Promise.resolve();
			const response = await requestApi<TResult>(verb, partialPath, params);
			await minDelayPromise;
			setState({ requesting: false, ...response });
			if (response.type === 'failure') {
				dispatchToast(errorMessage(response.failure, RequestFailureMessages[response.failure]));
			}
			return response;
		},
		[verb, partialPath, dispatchToast, options]
	);

	const updateDataOptimistically = useCallback(
		(newDataProvider: (oldData: TResult) => TResult) =>
			setState((oldState) => {
				if (oldState.type !== 'success') {
					return oldState;
				}

				return { ...oldState, data: newDataProvider(oldState.data) };
			}),
		[setState]
	);

	return [state, request, updateDataOptimistically];
}

export function useApiFetch<TResult>(partialPath: string): UseApiReturnValue<TResult, undefined> {
	const [response, request, updateDataOptimistically] = useApi<undefined, TResult>('GET', partialPath);

	useLayoutEffect(() => {
		request(undefined);
	}, [request]);

	return [response, request, updateDataOptimistically];
}

interface RequestApiStreamingResult {
	type: 'streaming_started';
	fetchResponse: Response;
}

interface RequestApiStreamingDataResult<TData> {
	type: 'streaming';
	data: TData;
	fetchResponse: Response;
}

export type RequestApiStreamResult<TData> =
	| RequestApiErrorResult
	| RequestApiStreamingResult
	| RequestApiStreamingDataResult<TData[]>
	| RequestApiFailureResult;

export type UseApiStreamReturnValue<TData, TParams> = [
	UseApiStreamState<TData>,
	(args: TParams) => Promise<RequestApiStreamResult<TData>>,
	(optimisticallyDataProvider: (currentData: TData[]) => TData[]) => void,
];

export type UseApiStreamState<TData> = {
	requesting: boolean;
} & (RequestApiStreamResult<TData> | { type?: undefined });

export const requestApiStream = async <T>(
	verb: HttpVerb,
	path: string,
	data: unknown,
	objectReceivedCallback: (data: T) => void,
	streamFinishedCallback: () => void
): Promise<RequestApiStreamResult<T>> => {
	try {
		const response = await fetch(`/api/login-fortnox-id/${path}`, {
			method: verb,
			body: verb !== 'GET' ? JSON.stringify(data) : undefined,
			headers: {
				'X-Requested-With': 'login-fortnox-id-ui',
			},
		});

		if (response.status >= 500) {
			console.error('API request server error response', verb, path, response.status, response.statusText);

			return {
				type: 'failure',
				failure: 'server-error',
			};
		}

		if (!response.ok || response.status === 204 || response.body === null) {
			return { error: await response.json(), type: 'error' };
		}

		const reader = response.body.getReader();
		// Safari schedules the reader in startAsyncParseJsonArrayFromReader differently from Chrome. It called the objectReceivedCallback
		// before the return state 'type':'streaming_started' below was propagated to the callers objectReceivedCallback.
		// Adding a setTimeout here to delay the reader start.
		setTimeout(() => startAsyncParseJsonArrayFromReader(reader, objectReceivedCallback, streamFinishedCallback), 0);

		return { type: 'streaming_started', fetchResponse: response };
	} catch (e) {
		console.error('API request exception', verb, path, e);
		return {
			type: 'failure',
			failure: 'network-error',
		};
	}
};

export function useApiStream<TParams, TResult = unknown>(
	verb: HttpVerb,
	partialPath: string
): UseApiStreamReturnValue<TResult, TParams> {
	const dispatchToast = useDispatchToast();
	const [state, setState] = useState<UseApiStreamState<TResult>>({
		requesting: false,
	});

	const request = useCallback(
		async (params: TParams) => {
			setState({ requesting: true });

			const objectReceived = (data: TResult) => {
				setState((oldState) => {
					if (oldState.type !== 'streaming_started' && oldState.type !== 'streaming') {
						// invalid state transition, can only receive additional data when streaming
						return {
							type: 'failure',
							requesting: false,
							failure: 'server-error',
						};
					}
					return {
						type: 'streaming',
						requesting: true,
						fetchResponse: oldState.fetchResponse,
						data: oldState.type === 'streaming_started' ? [data] : [...oldState.data, data],
					};
				});
			};

			const streamFinished = () => {
				setState((oldState) => {
					if (oldState.type !== 'streaming_started' && oldState.type !== 'streaming') {
						// invalid state transition, can only finish streaming when streaming
						return {
							type: 'failure',
							requesting: false,
							failure: 'server-error',
						};
					}
					return {
						type: 'streaming',
						requesting: false,
						fetchResponse: oldState.fetchResponse,
						data: oldState.type === 'streaming_started' ? [] : oldState.data,
					};
				});
			};

			const response = await requestApiStream<TResult>(verb, partialPath, params, objectReceived, streamFinished);

			setState({ ...response, requesting: response.type === 'streaming_started' });
			if (response.type === 'failure') {
				dispatchToast(errorMessage(response.failure, RequestFailureMessages[response.failure]));
			}
			return response;
		},
		[verb, partialPath, dispatchToast]
	);

	const updateDataOptimistically = useCallback(
		(newDataProvider: (oldData: TResult[]) => TResult[]) =>
			setState((oldState) => {
				if (oldState.type !== 'streaming') {
					return oldState;
				}

				return { ...oldState, data: newDataProvider(oldState.data) };
			}),
		[setState]
	);

	return [state, request, updateDataOptimistically];
}

export function useApiFetchStream<TResult>(partialPath: string): UseApiStreamReturnValue<TResult, undefined> {
	const [response, request, updateDataOptimistically] = useApiStream<undefined, TResult>('GET', partialPath);

	useLayoutEffect(() => {
		request(undefined);
	}, [request]);

	return [response, request, updateDataOptimistically];
}
