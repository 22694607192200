import React from 'react';
import { useHistory } from 'react-router';
import { COLORS, Text } from '@fnox/eternal-smooth-ui';
import { platform } from '@fnox/fabstracta';
import styles from './TenantSelect.module.css';
import { TenantAction, TenantSelectOption } from './TenantSelectOption';
import { EmployeeOption } from './common';
import { SelectedTenantOption } from './useTenantSelector';

export const EmployeeSelectOption = (props: {
	option: EmployeeOption;
	disabled?: boolean;
	onSelect: (selected: SelectedTenantOption) => void;
	actions: TenantAction[];
}) => {
	const history = useHistory();
	const option = props.option;
	const [, setSelectedEmployee] = platform.useEntity<EmployeeOption>('tenantSelect.employee');

	const selectOption: SelectedTenantOption = {
		type: 'EMPLOYEE',
		tenantId: option.tenantId,
		id: option.employeeId,
		companyName: option.companyName,
	};

	const handleOnClick = (selected: SelectedTenantOption) => {
		// If email is set submit employee selection otherwise send user to tenant-select/employee email screen
		if (option.email) {
			props.onSelect(selected);
		} else {
			setSelectedEmployee(option);
			history.push(`/login-fortnox-id/tenant-select/employee`);
		}
	};

	return (
		<TenantSelectOption
			option={selectOption}
			isBureau={option.isBureau}
			onClick={handleOnClick}
			devNotice={option.companyNameErrorMessage}
			actions={props.actions}
			isLoadingContext={false}
			canShowLoading={false}
			numTodos={0}
			numUnreadMessages={0}
		>
			<TenantSelectOption.Header option={option} />
			{option.organizationNumber && (
				<Text size="small" color={COLORS.ReadOnly} className={styles.orgNumber}>
					{option.organizationNumber}
				</Text>
			)}
			<TenantSelectOption.Labels option={option} />
		</TenantSelectOption>
	);
};
