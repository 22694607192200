import './instrument';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster as EternalToaster } from '@fnox/eternal-smooth-ui';
import { platform } from '@fnox/fabstracta';
import { clearMessage } from '@fnox/fabstracta-platform';
import { Localize } from './Localize';
import Routes from './Routes';
import './accessibility';
import { FortnoxWebAnalytics } from './components/FortnoxWebAnalytics/FortnoxWebAnalytics';
import './icons';
import { store } from './store/configureStore';
import './style/style.css';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { useUnhandledErrorLogger } from 'components/ErrorBoundary/useErrorHandler';
import { TimeProvider } from 'components/TimeProvider';

localStorage.setItem('framework', 'fabstracta');

// Disable BF-cache to avoid issues when backing into the app again
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.addEventListener('unload', () => {});
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.addEventListener('beforeunload', () => {});

type MessageEntries = React.ComponentProps<typeof EternalToaster>['entries'];

// Routing configuration
const App: React.FunctionComponent = () => {
	useUnhandledErrorLogger();

	return (
		<Router>
			<ErrorBoundary>
				<Provider store={store}>
					<TimeProvider>
						<Localize>
							{() => (
								<>
									<FortnoxWebAnalytics />
									<Toaster />
									<Routes />
								</>
							)}
						</Localize>
					</TimeProvider>
				</Provider>
			</ErrorBoundary>
		</Router>
	);
};

function Toaster() {
	const dispatch = useDispatch();
	const entries = platform.useFabstracta((state) => state.messages.entries as MessageEntries);
	const closeToast = React.useCallback(
		(toastId: number) => {
			dispatch(clearMessage({ id: toastId, ts: new Date().getTime() }));
		},
		[dispatch]
	);
	return <EternalToaster entries={entries} closeToast={closeToast} />;
}

if (process.env.RENDER_APP) {
	const rootNode = document.getElementById('root');
	const root = createRoot(rootNode!);
	root.render(<App />);
}

export default App;
