import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { jt, t } from 'ttag';
import { Container, Heading, Spinner, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import confetti from '../images/confetti.svg';
import { DownloadAppInviteAcceptedScreen } from './DownloadApp';
import { InviteInfo } from './types';
import { Box } from 'components/Box';
import { LoadingButton } from 'components/LoadingButton';
import { useApi, useApiFetch } from 'hooks/useApi';
import { useRedirect } from 'hooks/useRedirect';

export const InviteAcceptedIndex = () => {
	const redirect = useRedirect();
	const history = useHistory();

	const [loading, setSubmitting] = useState(false);
	const [, acceptedContinue] = useApi<void, string>('POST', 'ui-invite-v1/accepted-continue');

	const handleAcceptedContinue = async () => {
		setSubmitting(true);
		const result = await acceptedContinue();
		if (result.type === 'success') {
			redirect(result.data, true);
		} else if (result.type === 'error') {
			history.replace('/login-fortnox-id/error/' + result.error.error);
		} else {
			setSubmitting(false);
		}
	};

	const [inviteInfoRequest] = useApiFetch<InviteInfo>('ui-invite-v1/fetch-invite-info');

	if (inviteInfoRequest.requesting || inviteInfoRequest.type === undefined) {
		return (
			<Container maxWidth="sm" centerHorizontally padding="md">
				<Header mb="xl" />
				<Spinner enabled />
			</Container>
		);
	}

	if (inviteInfoRequest.type === 'failure') {
		return <Redirect to="/login-fortnox-id/error/SYSTEM_ERROR" />;
	}

	if (inviteInfoRequest.type === 'error') {
		if (inviteInfoRequest.error.error === 'invalid.invite') {
			redirect('/api/login-fortnox-id/web-login-v1');
			return null;
		}

		return <Redirect to={'/login-fortnox-id/error/' + inviteInfoRequest.error.error} />;
	}

	const inviteInfo = inviteInfoRequest.data;

	const onNext = async () => {
		if (inviteInfo.isBureauClient || inviteInfo?.variant == 'APP') {
			history.push('accepted/download-app');
		} else {
			await handleAcceptedContinue();
		}
	};

	return (
		<Switch>
			<Route path="/login-fortnox-id/invite/accepted/download-app">
				<DownloadAppInviteAcceptedScreen onNext={handleAcceptedContinue} />
			</Route>
			<Route path="/login-fortnox-id/invite/accepted">
				<InviteAcceptedScreen onNext={onNext} onNextLoading={loading} inviteInfo={inviteInfo} />
			</Route>
		</Switch>
	);
};

type InviteAcceptedScreenProps = {
	inviteInfo: InviteInfo;
	onNext: () => void;
	onNextLoading: boolean;
};

const InviteAcceptedScreen = (props: InviteAcceptedScreenProps) => {
	const inviteInfo = props.inviteInfo;
	const styledCompanyName = <strong key="company">{inviteInfo.companyName}</strong>;
	const companyName = inviteInfo.companyName;
	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb="xl" />
			<Box mb="xl">
				<Heading as="h2">{t`${companyName} kopplat till ditt Fortnox ID`}</Heading>
			</Box>
			<Box mb="xl" textAlign="center">
				<img src={confetti} role="presentation" alt="" />
			</Box>
			<Box mb="xl">
				<Text>{jt`Nu kan du logga in i ${styledCompanyName} med ditt Fortnox ID.`}</Text>
			</Box>
			<Box display="flex" flexDirection="column">
				<Box alignSelf={{ base: 'stretch', sm: 'flex-end' }}>
					<LoadingButton
						disabled={props.onNextLoading}
						loading={props.onNextLoading}
						onClick={props.onNext}
						type="button"
						block
					>
						{t`Fortsätt`}
					</LoadingButton>
				</Box>
			</Box>
		</Container>
	);
};
