import React, { useState } from 'react';
import {
	AwesomeIcon,
	AwesomeIconButton,
	COLORS,
	Column,
	Heading,
	List,
	Row,
	RowExpandable,
} from '@fnox/eternal-smooth-ui';
import { Box } from '../Box';
import styles from './Accordion.module.css';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const AccordionHeader = ({
	title,
	expanded,
	icon,
	testID,
}: {
	title: string;
	expanded: boolean;
	icon: React.ReactNode;
	testID?: string;
}) => (
	<Row className={styles.noMarginBottom} testID={testID}>
		<Column>
			<Box display="flex" gap="xs">
				{icon}
				<Box>
					<Heading as="h3">{title}</Heading>
				</Box>
			</Box>
		</Column>
		<Column collapse>
			{!expanded && <AwesomeIconButton name="chevron-down" aria-label="show-expanded" color={COLORS.TextDark} />}
		</Column>
	</Row>
);

export const Accordion = (props: {
	title: string;
	children: React.ReactNode;
	icon: IconName;
	testID?: string;
	initiallyExpanded: boolean;
	onChange?: (expanded: boolean) => void;
}) => {
	const [expanded, setExpanded] = useState(props.initiallyExpanded);
	return (
		<List testID={props.testID} className={styles.chevronColor}>
			<RowExpandable
				onChange={() => {
					if (props.onChange) {
						props.onChange(!expanded);
					}
					setExpanded(!expanded);
				}}
				className={styles.noMarginBottom}
				renderExpanded={() => (
					<>
						<AccordionHeader title={props.title} icon={<AwesomeIcon name={props.icon} />} expanded={true} />
						<Box paddingBottom="xs">{props.children}</Box>
					</>
				)}
				expanded={expanded}
			>
				<Row>
					<AccordionHeader title={props.title} icon={<AwesomeIcon name={props.icon} />} expanded={false} />
				</Row>
			</RowExpandable>
		</List>
	);
};
