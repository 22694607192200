export function useRedirect() {
	return redirect;
}

export function redirect(uri: string, replace = false) {
	const exec = replace ? locationReplace : locationAssign;
	exec(uri);
}

export function locationReplace(uri) {
	window.location.replace(uri);
}

export function locationAssign(uri) {
	window.location.assign(uri);
}
