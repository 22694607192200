import React from 'react';
import cls from 'classnames';
import { Box } from '../components';
import { DemoUtbActivationContent } from './DemoUtbActivationContent';
import styles from './DemoUtbActivationScreen.module.css';

export const DemoUtbActivationScreen = () => {
	return (
		<Box flex={1} display="flex" flexDirection="row" className={styles.container}>
			<Box
				display="flex"
				p={{ base: 'md', md: 'xl', lg: '3xl' }}
				className={styles.subContainer}
				style={{ overflowY: 'auto', overflowX: 'hidden' }}
				justifyContent="center"
			>
				<DemoUtbActivationContent />
			</Box>
			<Box display={{ base: 'none', xl: 'block' }} className={cls([styles.subContainer, styles.imageContainer])} />
		</Box>
	);
};
