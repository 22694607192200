import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'ttag';
import { Container, Heading, Text } from '@fnox/eternal-smooth-ui';
import { Header } from '../components/Header';
import { MIGRATION_TIMEOUT_MINUTES } from '../constants';
import { useApi } from '../hooks/useApi';
import { redirect } from 'common/redirect';
import { Box } from 'components/Box';
import { LoadingButton } from 'components/LoadingButton';

export const MigrationExpiredScreen = () => {
	const history = useHistory();
	const [restarting, setRestarting] = useState(false);
	const [, restartLogin] = useApi<undefined, string>('POST', 'web-login-v1/migration/restart-login');

	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb={{ base: 'md', sm: 'xl' }} />
			<Box mb="md">
				<Heading as="h1">{t`Du är utloggad`}</Heading>
			</Box>
			<Box mb="xl">
				<Text>
					{t`Du blir av säkerhetsskäl utloggad om du inte avslutar inloggningen inom ${MIGRATION_TIMEOUT_MINUTES} minuter.`}
				</Text>
			</Box>
			<LoadingButton
				loading={restarting}
				block
				onClick={async () => {
					setRestarting(true);
					const result = await restartLogin(undefined);
					if (result.type === 'success') {
						redirect(result.data, true);
					} else {
						history.replace('/login-fortnox-id/error/USER_ENVIRONMENT_ERROR');
					}
				}}
			>
				{t`Logga in igen`}
			</LoadingButton>
		</Container>
	);
};
