import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Box, Divider, Link as LinkWithPing, LoadingButton, Stack } from 'components';
import { jt, t } from 'ttag';
import { Alert, AwesomeIcon, Button, COLORS, Dialog, Heading, Spinner, Text, TextField } from '@fnox/eternal-smooth-ui';
import { errorMessage, successMessage } from '@fnox/fabstracta-platform';
import { track } from '../common/tracking';
import { sendClickMetrics, useApi, useApiFetch } from '../hooks/useApi';
import bankIdLogo from '../images/bankid.svg';
import directionSignSrc from '../images/direction-sign.png';
import { ContextUserPreferences, TenantSelectContext, metricsPingURI } from './common';
import { useRedirect } from 'hooks/useRedirect';

type CompanyEngagements = {
	hasAnyEngagements: boolean;
	engagements: CompanyEngagement[];
	canRequestTenantAccess: boolean;
};

type CompanyEngagement =
	// Splitting the type into two makes it better with type guards
	| {
			organizationNumber: string | null;
			companyName: string;
			roleName: string;
			tenant: null;
	  }
	| {
			organizationNumber: string | null;
			companyName: string;
			roleName: string;
			tenant: TenantInfo;
	  };

type TenantInfo = {
	id: number;
	isCompanyManager: boolean;
	isDeveloper: boolean;
	isBureau: boolean;
};

export function GatherYourBusinesses(props: { context: TenantSelectContext; hasTenantUsers: boolean }) {
	const [companyEngagementsRequest] = useApiFetch<CompanyEngagements>('ui-login-v1/company-engagements');
	const [, saveUserPreferences] = useApi<ContextUserPreferences, void>('POST', 'user-preferences-v1/save');

	if (companyEngagementsRequest.requesting) {
		return (
			<Box p="md">
				<Spinner enabled />
			</Box>
		);
	}

	if (companyEngagementsRequest.type !== 'success') {
		return <Alert type="warning">{t`Misslyckades att slå upp information om dina företag.`}</Alert>;
	}

	if (props.hasTenantUsers) {
		if (!companyEngagementsRequest.data.hasAnyEngagements) {
			return null;
		}
		return (
			<Accordion
				title={t`Samla dina företag`}
				icon="puzzle-piece"
				initiallyExpanded={props.context.userPreferences.companyEngagementsCollapsed != true}
				onChange={(expanded) => {
					saveUserPreferences({ companyEngagementsCollapsed: !expanded });
				}}
				testID="company-engagements-accordion"
			>
				<Box mt="md">
					<CompanyEngagementList companyEngagements={companyEngagementsRequest.data} />
				</Box>
				<BuyStockCompany />
			</Accordion>
		);
	}

	return (
		<Box>
			<CompanyEngagementList companyEngagements={companyEngagementsRequest.data} />
			<BuyStockCompany />
		</Box>
	);
}

type CompanyEngagementWithTenant = CompanyEngagement & { tenant: TenantInfo };

export function CompanyEngagementList(props: { companyEngagements: CompanyEngagements }) {
	const engagementsWithFortnox: Array<CompanyEngagementWithTenant> = props.companyEngagements.engagements
		.filter((x): x is CompanyEngagementWithTenant => !!x.tenant)
		.sort(
			(a, b) =>
				a.companyName.localeCompare(b.companyName) ||
				(a.organizationNumber ?? '').localeCompare(b.organizationNumber ?? '') ||
				a.tenant.id - b.tenant.id
		);

	const engagementsWithoutFortnox = props.companyEngagements.engagements
		.filter((x) => !x.tenant)
		.sort(
			(a, b) =>
				a.companyName.localeCompare(b.companyName) ||
				(a.organizationNumber ?? '').localeCompare(b.organizationNumber ?? '')
		);

	return (
		<Stack testID="company-engagements-container">
			{(engagementsWithFortnox.length > 0 || engagementsWithoutFortnox.length > 0) && (
				<Box mb="md">
					{t`Nedan visas företag där du har en position registrerad hos Bolagsverket. Syftet med detta är att göra det enkelt för dig att få tillgång till eller skaffa Fortnox till dina andra företag så du kan sköta dina företag på en plats.`}
				</Box>
			)}
			{engagementsWithFortnox.length > 0 && (
				<Stack gap="xs" mt="md" mb="xl">
					<Heading as="h4">{t`Har Fortnox`}</Heading>
					<Divider />
					{engagementsWithFortnox.map((engagement, i) => (
						<CompanyEngagementWithFortnox
							key={i}
							engagement={engagement}
							canRequestTenantAccess={props.companyEngagements.canRequestTenantAccess}
							showTenantId={engagementsWithFortnox.some(
								(x) => x.organizationNumber === engagement.organizationNumber && x.tenant.id !== engagement.tenant.id
							)}
						/>
					))}
				</Stack>
			)}

			{engagementsWithoutFortnox.length > 0 ? (
				<Stack gap="xs" mt="md" mb="xl">
					<Heading as="h4">{t`Har ännu inte Fortnox`}</Heading>
					<Divider />
					{engagementsWithoutFortnox.map((engagement, i) => (
						<CompanyEngagementWithoutFortnox key={i} engagement={engagement} />
					))}
				</Stack>
			) : (
				<Box mb="md">
					<BuyGuide />
				</Box>
			)}
		</Stack>
	);
}

function translateRoleName(roleName: string) {
	switch (roleName) {
		case 'Suppleant':
			return t`Suppleant`;
		case 'Verkställande direktör':
			return t`Verkställande direktör`;
		case 'Vice verkställande direktör':
			return t`Vice verkställande direktör`;
		case 'Bolagsman':
			return t`Bolagsman`;
		case 'Kommanditdelägare':
			return t`Kommanditdelägare`;
		case 'Ordförande':
			return t`Ordförande`;
		case 'Extern firmatecknare':
			return t`Extern firmatecknare`;
		case 'Ställföreträdande VD':
			return t`Ställföreträdande VD`;
		case 'Verkställande ledamot':
			return t`Verkställande ledamot`;
		case 'Vice ordförande':
			return t`Vice ordförande`;
		case 'Extern verkställande direktör':
			return t`Extern verkställande direktör`;
		case 'Extern vice verkställande direktör':
			return t`Extern vice verkställande direktör`;
		case 'Ledamot':
			return t`Ledamot`;
		case 'Komplementär':
			return t`Komplementär`;
		case 'Innehavare':
			return t`Innehavare`;
		case 'Prokurist':
			return t`Prokurist`;
		default:
			return null;
	}
}

function CompanyEngagementWithoutFortnox({ engagement }: { engagement: CompanyEngagement }) {
	const translatedRoleName = translateRoleName(engagement.roleName);
	return (
		<Box display="flex" gap="md">
			<Stack flex={1} gap="2xs" overflow="hidden">
				<Text className="truncate">{engagement.companyName}</Text>
				<Text size="small" color={COLORS.ReadOnly}>
					{engagement.organizationNumber
						? engagement.organizationNumber.substring(0, 6) + '-' + engagement.organizationNumber.substring(6, 10)
						: null}
					{translatedRoleName && <> - {t`Din roll är: ${translatedRoleName}`}</>}
				</Text>
			</Stack>
			<Box width="auto">
				<BuySubscriptionLink />
			</Box>
		</Box>
	);
}

function CompanyEngagementWithFortnox({
	engagement,
	canRequestTenantAccess,
	showTenantId,
}: {
	engagement: CompanyEngagementWithTenant;
	canRequestTenantAccess: boolean;
	showTenantId: boolean;
}) {
	const translatedRoleName = translateRoleName(engagement.roleName);
	return (
		<Box display="flex" gap="md">
			<Stack flex={1} gap="2xs" overflow="hidden">
				<Box display="flex" gap="xs" alignItems="flex-end">
					<Text className="truncate">{engagement.companyName}</Text>
					{showTenantId ? (
						<Text as="span" size="small" color={COLORS.ReadOnly}>
							{engagement.tenant.id}
						</Text>
					) : null}
				</Box>
				<Text size="small" color={COLORS.ReadOnly}>
					{engagement.organizationNumber
						? engagement.organizationNumber.substring(0, 6) + '-' + engagement.organizationNumber.substring(6, 10)
						: null}
					{translatedRoleName && <> - {t`Din roll är: ${translatedRoleName}`}</>}
				</Text>
			</Stack>
			<Box width="auto" flexShrink={0}>
				<RequestTenantAccessButton companyEngagement={engagement} canRequestTenantAccess={canRequestTenantAccess} />
			</Box>
		</Box>
	);
}

function RequestTenantAccessButton({
	companyEngagement,
	canRequestTenantAccess,
}: {
	companyEngagement: CompanyEngagementWithTenant;
	canRequestTenantAccess?: boolean;
}) {
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<Button
				size="small"
				secondary
				onClick={() => {
					setOpen(true);
					track(
						'request_tenant_access:open_dialog',
						canRequestTenantAccess ? 'can-request-without-login' : 'requires-login-before-request'
					);
				}}
			>{t`Be om åtkomst...`}</Button>
			{canRequestTenantAccess ? (
				<RequestTenantAccessDialog companyEngagement={companyEngagement} open={open} onClose={() => setOpen(false)} />
			) : (
				<RequestTenantAccessRequiresLoginDialog
					companyEngagement={companyEngagement}
					open={open}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}

function RequestTenantAccessDialog({
	companyEngagement,
	onClose,
	open,
}: {
	companyEngagement: CompanyEngagementWithTenant;
	onClose: () => void;
	open: boolean;
}) {
	const dispatch = useDispatch();
	const [accessRequest, requestTenantAccess] = useApi<{ tenantId: number; message: string }>(
		'POST',
		'ui-login-v1/company-engagements/request-tenant-access'
	);
	const [message, setMessage] = useState('');

	function clearAndClose() {
		setMessage('');
		onClose();
	}

	return (
		<Dialog onClose={clearAndClose} open={open} showCloseIcon className="max-w-640">
			<Box mb="md">
				<Heading as="h2">{t`Be om åtkomst till ${companyEngagement.companyName}`}</Heading>
			</Box>
			<Box mb="md">{jt`Din förfrågan kommer förmedlas till systemadministratörer som kan skicka en inbjudan till dig.`}</Box>
			<Box mb="md">{jt`Notera att ditt namn, personnummer och eventuellt angiven e-postadress kommer delas med samtliga systemadministratörer på ${companyEngagement.companyName}.`}</Box>
			<Box mb="xl">
				<TextField
					value={message}
					onChange={(e) => setMessage(e.currentTarget.value)}
					title={t`Meddelande till systemadministratörer`}
					maxLength={200}
				/>
			</Box>
			<Box display="flex" justifyContent="flex-end" gap="md">
				<Button secondary onClick={clearAndClose}>
					{t`Avbryt`}
				</Button>
				<LoadingButton
					onClick={async () => {
						sendClickMetrics('request_tenant_access');
						const result = await requestTenantAccess({ tenantId: companyEngagement.tenant.id, message });
						if (result.type === 'success') {
							dispatch(successMessage('', t`Förfrågan skickad`));
							setMessage('');
							clearAndClose();
							track('request_tenant_access:success');
						} else if (result.type === 'error') {
							track('request_tenant_access:error', result.error.error);
							if (result.error.error === 'unauthorized') {
								dispatch(
									errorMessage(
										'',
										t`Du saknar behörighet att skicka förfrågan vid denna tidpunkt. Observera att BankID inloggning krävs.`,
										10 * 1000
									)
								);
							} else {
								dispatch(errorMessage('', t`Misslyckades att skicka förfrågan`));
							}
						}
					}}
					loading={accessRequest.requesting}
				>{t`Skicka förfrågan`}</LoadingButton>
			</Box>
		</Dialog>
	);
}

function RequestTenantAccessRequiresLoginDialog({
	onClose,
	open,
}: {
	companyEngagement: CompanyEngagementWithTenant;
	onClose: () => void;
	open: boolean;
}) {
	const redirect = useRedirect();
	const [redirecting, setRedirecting] = React.useState(false);

	return (
		<Dialog onClose={onClose} open={open} showCloseIcon className="max-w-640">
			<Box mb="md">
				<Heading as="h2">{t`Du behöver vara inloggad med BankID för att begära åtkomst`}</Heading>
			</Box>
			<Box mb="xl">{t`För att rätt person ska få tillgång till rätt företag så behöver vi säkerställa din identitet innan du begär åtkomst.`}</Box>
			<Box display="flex" justifyContent="flex-end" gap="md">
				<Button secondary onClick={onClose}>
					{t`Avbryt`}
				</Button>
				<LoadingButton
					onClick={async () => {
						setRedirecting(true);
						sendClickMetrics('start_bankid_login_to_request_tenant_access');
						redirect('/api/login-fortnox-id/web-login-v1?bank_id=true');
					}}
					loading={redirecting}
				>
					<img src={bankIdLogo} alt="BankID Logo" width={16} style={{ marginRight: 16 }} />
					{t`Logga in med BankID`}
				</LoadingButton>
			</Box>
		</Dialog>
	);
}

function BuySubscriptionLink() {
	return (
		<LinkWithPing to="https://buy-guide.fortnox.se" variant="external" ping={metricsPingURI('buy_subscription')}>
			{t`Till vår köpguide`}
		</LinkWithPing>
	);
}

export function BuyGuide() {
	return (
		<Box borderRadius="md" p="md" display="flex" gap="md" border="line-color" alignItems="center">
			<GatherCompanyIconContainer>
				<img src={directionSignSrc} alt="Direction sign" height="32px" />
			</GatherCompanyIconContainer>
			<Stack>
				<Box>
					<LinkWithPing to="https://buy-guide.fortnox.se" variant="external" ping={metricsPingURI('buy_guide')}>
						{t`Välj rätt program - prova vår köpguide`}
					</LinkWithPing>
				</Box>
				<Text>
					{t`Få hjälp att välja vad du behöver i vår plattform för att göra ditt företag extra framgångsrikt.`}
				</Text>
			</Stack>
		</Box>
	);
}

export function BuyStockCompany() {
	return (
		<Box borderRadius="md" backgroundColor="selected" p="md" display="flex" gap="md">
			<GatherCompanyIconContainer>
				<AwesomeIcon name="seedling" />
			</GatherCompanyIconContainer>
			<Stack>
				<Box>
					<LinkWithPing to="https://www.fortnox.se/lagerbolag" variant="external" ping={metricsPingURI('stockcompany')}>
						{t`Starta nytt aktiebolag`}
					</LinkWithPing>
				</Box>
				<Text>
					{t`Köp ett färdigt aktiebolag via Fortnox - Du får organisationsnummer och samtliga bolagshandlingar inom 2 timmar.`}
				</Text>
			</Stack>
		</Box>
	);
}

function GatherCompanyIconContainer({ children }: React.PropsWithChildren) {
	return (
		<Box display="flex" justifyContent="center" flexShrink={0} style={{ width: 32 }}>
			{children}
		</Box>
	);
}
