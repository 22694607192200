import React from 'react';
import cls from 'classnames';
import { COLORS, Text } from '@fnox/eternal-smooth-ui';
import styles from './Chip.module.css';

export const Chip = (props: {
	children: React.ReactNode;
	variant?: 'default' | 'error';
	size?: 'default' | 'large';
	className?: string;
}) => (
	<span
		className={cls(
			styles.chip,
			styles['variant-' + (props.variant ?? 'default')],
			styles['size-' + (props.size ?? 'default')],
			props.className
		)}
	>
		<Text size={props.size == 'large' ? 'default' : 'small'} color={COLORS.TextDark}>
			{props.children}
		</Text>
	</span>
);
