import React from 'react';
import classnames from 'classnames';
import { Box, Chip, Stack } from 'components';
import { t } from 'ttag';
import { AwesomeIcon, AwesomeIconButton, COLORS, Heading, IconTooltip, Text } from '@fnox/eternal-smooth-ui';
import styles from './TenantSelect.module.css';
import { TodoCount } from './TodoCount';
import { UnreadMessagesCount } from './UnreadMessagesCount';
import { BaseOption, LoginOption, TenantUserOption, isTenantUserOption } from './common';
import { SelectedTenantOption } from './useTenantSelector';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const xxs = '4px';

type AnchorMouseEvent = React.MouseEvent<HTMLAnchorElement>;
export type TenantAction = {
	color?: (typeof COLORS)[keyof typeof COLORS];
	icon: IconName;
	label: string;
	onSelect: () => void;
};

type TenantSelectOptionProps = {
	option: SelectedTenantOption;
	numTodos?: number;
	numUnreadMessages?: number;
	isLoadingContext: boolean;
	canShowLoading: boolean;
	isBureau: boolean;
	disabled?: boolean;
	onClick: (selected: SelectedTenantOption) => void;
	children: React.ReactNode;
	actions?: TenantAction[];
	// Currently just used for marking the tenant user that has a mismatch of f3 database version
	devNotice: string | null;
};

const TenantSelectOptionWrapper = ({
	option,
	numTodos,
	numUnreadMessages,
	isLoadingContext,
	canShowLoading,
	isBureau,
	disabled,
	onClick,
	children,
	actions,
	devNotice,
}: TenantSelectOptionProps) => {
	const typeClassName = option.type === 'USER' ? 'tenant-option' : 'employee-option';
	const additionalProps = option.type === 'USER' ? { id: `tenant-option-${option.tenantId}-${option.id}` } : {};

	const handleOnClick = (e: AnchorMouseEvent) => {
		e.preventDefault();
		if (disabled) return;
		onClick(option);
	};

	return (
		<div
			className={classnames(styles.tenantOption, typeClassName)}
			data-test-id={`tenant-option-${option.tenantId}-${option.id}`}
		>
			<a
				href="#"
				onClick={handleOnClick}
				{...additionalProps}
				className={classnames(styles.infoColumn, styles.optionColumn)}
				style={{ overflow: 'hidden' }}
				translate="no"
			>
				<Box display="flex" alignItems="center" gap="md">
					<div className={styles.asideIcons} data-test-id="aside-icons">
						{option.pinned && (
							<AwesomeIcon
								data-test-id="pinned-icon"
								className={styles.pinned}
								color={COLORS.BgWhite}
								name="thumb-tack"
								type="solid"
							/>
						)}
					</div>
					{devNotice && <TenantOptionDevAlert devAlert={devNotice} />}
					<Box display="flex" alignItems="center" width="full-width">
						<Stack gap="2xs" flex={1} overflow="hidden" mr="md">
							{children}
						</Stack>
						<Box display="flex" gap="md" justifyContent="flex-end" mr="xl" testID="info-container">
							{option.type === 'USER' && !isBureau && (
								<>
									<UnreadMessagesCount
										numUnreadMessages={numUnreadMessages}
										canShowLoading={canShowLoading}
										isLoadingContext={isLoadingContext}
									/>
									<TodoCount numTodos={numTodos} canShowLoading={canShowLoading} isLoadingContext={isLoadingContext} />
								</>
							)}
						</Box>
						<Box display={{ base: 'none', lg: 'flex' }}>
							<AwesomeIcon name="chevron-right" className={styles.chevronRightIcon} color={COLORS.TextDark} />
						</Box>
					</Box>
				</Box>
			</a>
			{actions && actions.length > 0 && (
				<TenantOptionDropdownActions
					actions={actions}
					testID={`tenant-option-menu-trigger-${option.tenantId}-${option.type === 'EMPLOYEE' ? 'employee-' : ''}${
						option.id
					}`}
				/>
			)}
		</div>
	);
};

// Internal use only
const TenantOptionDropdownActions = ({ actions, testID }: { actions: TenantAction[]; testID: string }) => (
	<DropdownMenu.Root>
		<DropdownMenu.Trigger asChild>
			<div className={classnames(styles.dropdownTrigger, styles.optionColumn)}>
				<AwesomeIconButton
					className={styles.triggerIcon}
					name="ellipsis-vertical"
					aria-label={t`Knapp för åtgärder`}
					testID={testID}
					color={COLORS.TextDark}
				/>
			</div>
		</DropdownMenu.Trigger>
		<DropdownMenu.Portal>
			<DropdownMenu.Content align="end" className={styles.actionsMenu}>
				{actions.map((action) => (
					<DropdownMenu.Item className={styles.actionsMenuItem} onClick={action.onSelect} key={action.label}>
						<AwesomeIcon color={action.color} name={action.icon} />
						<Text color={action.color}>{action.label}</Text>
					</DropdownMenu.Item>
				))}
			</DropdownMenu.Content>
		</DropdownMenu.Portal>
	</DropdownMenu.Root>
);

const TenantOptionHeader = ({
	option,
	identifierHint,
}: {
	option: BaseOption | TenantUserOption;
	identifierHint?: string;
}) => (
	<Box display="flex" gap="xs" alignItems="center" className={styles.header}>
		<Heading as="h4" color={COLORS.TextDark} className="truncate">
			{option.companyName ?? option.tenantId.toString()}
		</Heading>
		{isTenantUserOption(option) && option.isSystemAdmin && (
			<IconTooltip
				testID="sysAdmin-icon-tooltip"
				containerClassName={styles.systemAdminTooltipContainer}
				aria-label={t`Användaren är en systemadministratör`}
				icon={
					<AwesomeIcon
						data-test-id="sysAdmin-icon"
						color={COLORS.TextDark}
						name="crown"
						type="light"
						className={styles.crown}
					/>
				}
				tabIndex={-1}
				tooltip={t`Användaren är en systemadministratör`}
			/>
		)}
		{identifierHint && (
			<Box as="span" className={styles.identifierHint}>
				{identifierHint}
			</Box>
		)}
	</Box>
);

// Only for use in dev to highlight tenant option with tooltip info @GALAX-502
const TenantOptionDevAlert = ({ devAlert }: { devAlert: string }) => (
	<IconTooltip
		aria-label={t`Varning`}
		icon={<AwesomeIcon name="warning" color={COLORS.AccentOrange} />}
		position="top"
		testID="devalert-icon-tooltip"
		tabIndex={-1}
		tooltip={devAlert}
		containerClassName={styles.devAlertIconButton}
	/>
);

const TenantLabel = ({ children }: { children: React.ReactNode }) => (
	<Chip className={styles.tenantLabel}>{children}</Chip>
);

const TenantOptionLabels = (props: { option: LoginOption }) => {
	if (!props.option.isBureau && !props.option.isCompanyManager && !props.option.isDeveloper) {
		return null;
	}

	return (
		<div style={{ display: 'flex', flexWrap: 'nowrap', gap: xxs }}>
			{props.option.isBureau && <TenantLabel>{t`Digital byrå`}</TenantLabel>}
			{props.option.isCompanyManager && <TenantLabel>{t`Bolagshanterare`}</TenantLabel>}
			{props.option.isDeveloper && <TenantLabel>{t`Utvecklarportal`}</TenantLabel>}
		</div>
	);
};

export const TenantSelectOption = TenantSelectOptionWrapper as typeof TenantSelectOptionWrapper & {
	Header: typeof TenantOptionHeader;
	DevAlert: typeof TenantOptionDevAlert;
	Labels: typeof TenantOptionLabels;
};

TenantSelectOption.Header = TenantOptionHeader;
TenantSelectOption.DevAlert = TenantOptionDevAlert;
TenantSelectOption.Labels = TenantOptionLabels;
