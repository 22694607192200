import { eternalSmoothIcons } from '@fnox/eternal-smooth-ui';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowDown,
	faArrowRightFromBracket,
	faArrowUpRightFromSquare,
	faArrowsRepeat,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronRight,
	faChevronUp,
	faCircleQuestion,
	faClose,
	faCrown,
	faEllipsisVertical,
	faEnvelope,
	faExclamationTriangle,
	faHeadset,
	faIdBadge,
	faInfoCircle,
	faLinkSimpleSlash,
	faList,
	faNote,
	faPuzzlePiece,
	faRocket,
	faSearch,
	faSeedling,
	faSidebar,
	faSidebarFlip,
	faSpinnerThird,
	faThumbTack,
	faTrashAlt,
	faWarning,
} from '@fortawesome/pro-light-svg-icons';
import { faThumbTack as faThumbTackSolid } from '@fortawesome/pro-solid-svg-icons';

library.add(
	eternalSmoothIcons,
	faCrown,
	faSpinnerThird,
	faArrowDown,
	faCircleQuestion,
	faSidebar,
	faSidebarFlip,
	faChevronRight,
	faExclamationTriangle,
	faTrashAlt,
	faArrowUpRightFromSquare,
	faInfoCircle,
	faClose,
	faHeadset,
	faEllipsisVertical,
	faChevronDown,
	faRocket,
	faChevronUp,
	faWarning,
	faCheck,
	faLinkSimpleSlash,
	faPuzzlePiece,
	faSeedling,
	faThumbTack,
	faThumbTackSolid,
	faNote,
	faCheckCircle,
	faList,
	faEnvelope,
	faSearch,
	faArrowsRepeat,
	faIdBadge,
	faArrowRightFromBracket
);
