import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { isUiTestRunning } from 'common/test';

type TimeContextValue = {
	offset: number;
	setOffset(offset: number): void;
};

export const TimeContext = createContext<TimeContextValue>({
	offset: 0,
	setOffset: () => void {},
});

export const useGetNow = () => {
	const offset = useContext(TimeContext).offset;
	return useMemo(() => () => Date.now() + offset, [offset]);
};

export const useServerTime = (serverDateString: string | null | false) => {
	const timeContext = useContext(TimeContext);
	const setOffset = timeContext.setOffset;
	useLayoutEffect(() => {
		// Update client time offset to compensate for time skew between server and client
		if (serverDateString && !isUiTestRunning()) {
			const serverTimestamp = Date.parse(serverDateString);
			const clientTimestamp = Date.now();
			setOffset(serverTimestamp - clientTimestamp);
		}
	}, [serverDateString, setOffset]);
};

const sessionStorageKey = 'test-time-offset-ms';

export const TimeProvider = ({ children }: { children: React.ReactNode }) => {
	const [offset, setOffset] = useState(() => getOffsetFromSessionStorage());

	useEffect(() => {
		const handleMessage = (ev: MessageEvent<any>): void => {
			if (ev.data?.type === 'test-time-offset-ms-updated') {
				setOffset(getOffsetFromSessionStorage());
			}
		};

		window.addEventListener('message', handleMessage);
		return () => window.removeEventListener('message', handleMessage);
	}, []);

	const timeContextValue = useMemo<TimeContextValue>(
		() => ({
			offset,
			setOffset,
		}),
		[offset]
	);

	return <TimeContext.Provider value={timeContextValue}>{children}</TimeContext.Provider>;
};

function getOffsetFromSessionStorage() {
	const sessionValue = sessionStorage?.getItem(sessionStorageKey);
	return sessionValue ? parseInt(sessionValue, 10) : 0;
}
