import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { t } from 'ttag';
import { Container, Heading, Text } from '@fnox/eternal-smooth-ui';
import { redirect } from '../common/redirect';
import { Box } from '../components/Box';
import { Header } from '../components/Header';
import { LoadingButton } from '../components/LoadingButton';
import { LOGIN_TIMEOUT_HOURS } from '../constants';
import { useApi } from '../hooks/useApi';

export const TenantSelectExpiredScreen = () => {
	const history = useHistory();
	const [restarting, setRestarting] = useState(false);
	const [, restartLogin] = useApi<undefined, string>('POST', 'web-login-v1/restart-login');

	return (
		<Container maxWidth="sm" centerHorizontally padding="md">
			<Header mb={{ base: 'md', sm: 'xl' }} />
			<Box mb="md">
				<Heading as="h1">{t`Du är utloggad`}</Heading>
			</Box>
			<Box mb="xl">
				<Text>
					{t`Du blir av säkerhetsskäl utloggad om du inte väljer ett företag inom ${LOGIN_TIMEOUT_HOURS} timmar.`}
				</Text>
			</Box>
			<LoadingButton
				loading={restarting}
				block
				onClick={async () => {
					setRestarting(true);
					const result = await restartLogin(undefined);
					if (result.type === 'success') {
						redirect(result.data, true);
					} else if (result.type === 'error') {
						history.replace('/login-fortnox-id/error/USER_ENVIRONMENT_ERROR');
					} else if (result.type === 'failure') {
						setRestarting(false);
					}
				}}
			>
				{t`Logga in igen`}
			</LoadingButton>
		</Container>
	);
};
