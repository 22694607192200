import React, { CSSProperties } from 'react';
import { Box, BoxStyleProps } from '../Box';

type StackProps = BoxStyleProps & {
	children?: React.ReactNode;
	className?: string;
	style?: CSSProperties | undefined;
	testID?: string;
};
export const Stack = (props: StackProps) => (
	<Box testID={props.testID} display="flex" flexDirection="column" {...props}>
		{props.children}
	</Box>
);
