import React, { createContext, useContext, useEffect, useState } from 'react';
import { initLocales } from './locales';
import * as Sentry from '@sentry/react';
import { getCookie } from 'common/cookie';

/**
 * Runtime loading translations using `ttag`.
 * Every app based on fabstracta-template comes with a script defined in package.json called `translate`
 * Once you run `npm run translate` ttag cli will extract the translations and convert the .po files to .json files
 * for swedish and english translations ( i18n/sv.po.json and i18n/en.po.json ).
 *
 * The `Localize` component will load and initialize the localization based on the browser setting.
 *
 * We need to make sure to re-render the app once the localization is initialized
 * and in order to do that we need to call the children as function as well as forceUpdate().
 *
 * @example usage
	<Localize>
		{(locale) => (<YourAppGoesHere />)}
	</Localize>
 */

const supportedLangs = ['sv', 'en'];
const defaultLang = 'sv';
const langCookie = 'loginfortnoxid.locale';

type LocaleContextValue = string;
export const LocaleContext = createContext<LocaleContextValue>(defaultLang);
export const useLocale = () => useContext(LocaleContext);

export const Localize = ({ children }) => {
	const navigatorLang = findSupportedLang(navigator.language);
	const cookieLang = findSupportedLang(getCookie(langCookie));
	const locale = cookieLang ?? navigatorLang ?? defaultLang;
	const [, updateState] = useState<any>();
	const [localeContext, setLocaleContext] = useState<LocaleContextValue>(defaultLang);

	useEffect(() => {
		document.documentElement.lang = locale;
		Sentry.setTag('fortnox_locale', locale);
		initLocales(locale).then((locale) => locale && updateState({}));
		setLocaleContext(locale);
	}, [locale]);

	return <LocaleContext.Provider value={localeContext}>{children(locale)}</LocaleContext.Provider>;
};

function findSupportedLang(locale: string | null | undefined) {
	if (!locale) {
		return null;
	}

	return supportedLangs.find((lang) => locale.startsWith(lang));
}
