import React, { CSSProperties, ReactNode } from 'react';
import classnames from 'classnames';
import { Sprinkles, sprinkles } from './sprinkles.css';

export type BoxStyleProps = Sprinkles;

export const Box = function ({
	as = 'div',
	children,
	className,
	testID,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	class: ignore,
	style,
	...sprinkleProps
}: {
	as?: string;
	children?: ReactNode;
	class?: string;
	testID?: string;
	className?: string;
	style?: CSSProperties | undefined;
} & Sprinkles) {
	const Component = as as any;
	return (
		<Component data-test-id={testID} style={style} className={classnames(className, sprinkles(sprinkleProps))}>
			{children}
		</Component>
	);
};
