export function parseUtcDate(str: string) {
	const year = parseInt(str.substring(0, 4), 10);
	const month = parseInt(str.substring(5, 5 + 2), 10) - 1;
	const day = parseInt(str.substring(8, 8 + 2), 10);
	const hours = parseInt(str.substring(11, 11 + 2), 10);
	const minutes = parseInt(str.substring(14, 14 + 2), 10);
	const seconds = parseInt(str.substring(17, 17 + 2), 10);

	return Date.UTC(year, month, day, hours, minutes, seconds);
}

export function formatUtcDate(str: string) {
	const date = new Date(parseUtcDate(str));
	return date.toLocaleString();
}
