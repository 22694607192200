import { useRef, useState } from 'react';

export type SelectedTenantOption = {
	type: 'USER' | 'EMPLOYEE';
	tenantId: number;
	// generic id. They name of the id is set to either userId or employeeId depending on type once it's submitted to the server
	id: string;
	companyName: string | null;
	pinned?: boolean;
	note?: string | null;
	isSystemAdmin?: boolean;
};

export function useTenantSelector() {
	const [selectingOption, setSelectingOption] = useState<SelectedTenantOption | null>(null);
	const formRef = useRef<HTMLFormElement | null>(null);
	const tenantIdRef = useRef<HTMLInputElement | null>(null);
	const idFieldRef = useRef<HTMLInputElement | null>(null);

	// Register the form that will be manipulated before being submitted
	function registerForm() {
		return {
			ref: formRef,
			method: 'post',
			style: { display: 'none' },
		};
	}

	function registerTenantIdField() {
		return { ref: tenantIdRef, type: 'hidden', name: 'tenantId' };
	}

	// Field name is set dynamically upon form submission according to the tenant option type
	function registerIdField() {
		return { ref: idFieldRef, type: 'hidden' };
	}

	function submitForm() {
		// Reset Fabstracta cached user
		window.sessionStorage.removeItem('currentUserRights');
		window.sessionStorage.removeItem('currentUser');
		formRef.current?.submit();
	}

	function selectTenantOption(selected: SelectedTenantOption) {
		if (!formRef?.current) throw new Error('Missing form ref');
		if (!tenantIdRef?.current) throw new Error('Missing tenant id field ref');
		if (!idFieldRef?.current) throw new Error('Missing id field ref');

		// Set action type according to option we're selecting
		const entityType = selected.type === 'USER' ? 'tenant' : 'employee';
		formRef.current.action = `/api/login-fortnox-id/web-login-v1/select-${entityType}`;

		// Set tenant id
		tenantIdRef.current.value = selected.tenantId.toString();
		// Set name of input according to option type, to be used by the server
		idFieldRef.current.name = selected.type === 'USER' ? 'userId' : 'employeeId';
		idFieldRef.current.value = selected.id;

		// Update that we're currently selecting this option. To be used to render conditional loaders when a submission is in progress
		setSelectingOption(selected);

		submitForm();
	}

	return {
		selectTenantOption,
		registerForm,
		registerTenantIdField,
		registerIdField,
		formRef,
		selectingOption,
	};
}
