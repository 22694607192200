import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { InviteRoutes } from 'Invite';
import { MigrationScreen } from 'Migration';
import { DemoUtbActivationScreen } from './DemoUtbActivation/DemoUtbActivationScreen';
import { ErrorScreen } from './Error';
import { DownloadAppLinksScreen } from './Links/DownloadAppLinks';
import { OnboardRoutes } from './Onboard';
import { PasswordDisabledLoginScreen } from './PasswordDisabled/PasswordDisabledScreen';
import { TenantSelectScreen } from './TenantSelect/TenantSelectScreen';
import { MigrationLoginExpiredScreen } from 'Migration/MigrationExpiredLoginResponse';
import { MigrationExpiredScreen } from 'Migration/MigrationExpiredScreen';

const Routes = () => (
	<Switch>
		<Route path="/login-fortnox-id/app-links">
			<DownloadAppLinksScreen />
		</Route>
		<Route path="/login-fortnox-id/demo-utb-activation">
			<DemoUtbActivationScreen />
		</Route>
		<Route path="/login-fortnox-id/password-disabled-login">
			<PasswordDisabledLoginScreen />
		</Route>
		<Route path="/login-fortnox-id/invite/*">
			<InviteRoutes />
		</Route>
		<Route path="/login-fortnox-id/onboard/*">
			<OnboardRoutes />
		</Route>
		<Route path="/login-fortnox-id/migration/expired">
			<MigrationExpiredScreen />
		</Route>
		<Route path="/login-fortnox-id/migration/login-expired">
			<MigrationLoginExpiredScreen />
		</Route>
		<Route path="/login-fortnox-id/migration">
			<MigrationScreen />
		</Route>
		<Route path="/login-fortnox-id/tenant-select">
			<TenantSelectScreen />
		</Route>
		<Route path="/login-fortnox-id/error">
			<ErrorScreen />
		</Route>
		<Route path="/">
			<Redirect to="/login-fortnox-id/error/NOT_FOUND" />
		</Route>
	</Switch>
);

export default Routes;
