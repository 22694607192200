import React from 'react';
import { ErrorView } from '../ErrorView/ErrorView';
import { frontendLog } from './frontendLog';

interface State {
	hasError: boolean;
}

/**
 * Error boundaries work like a JavaScript catch {} block, but for components.
 * Only class components can be error boundaries.
 * In practice, most of the time you’ll want to declare an error boundary component once and use it throughout your application.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<any, State> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch({ message }, { componentStack }) {
		// Log the error message and component stack
		// You can choose to add additional log data about your app in the data object
		frontendLog({ message, data: { stack: componentStack, userAgent: navigator.userAgent } });
	}

	render() {
		if (this.state.hasError) {
			return <ErrorView showGeneric={true} />;
		}

		return this.props.children;
	}
}
