import React from 'react';
import classnames from 'classnames';
import { t } from 'ttag';
import { AwesomeIcon, IconTooltip } from '@fnox/eternal-smooth-ui';
import { Box } from '../components';
import styles from './TenantSelect.module.css';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export function TodoCount(props: { numTodos?: number; isLoadingContext: boolean; canShowLoading: boolean }) {
	const state = calcState(props.numTodos, props.isLoadingContext, props.canShowLoading);
	if (!state) {
		return <div style={{ width: 36, height: 26 }}></div>;
	}

	return (
		<>
			<Box className={styles.infoIcon} display="flex">
				<IconTooltip
					aria-label={state.toolTip}
					testID="todos-icon-tooltip"
					icon={
						<AwesomeIcon
							data-test-id="todos-icon"
							className={classnames({ [styles.infoLoading]: state.loading })}
							name={state.icon}
						/>
					}
					position="top"
					tabIndex={-1}
					tooltip={state.toolTip}
				/>

				{props.numTodos != undefined && props.numTodos > 0 && <Box className={styles.infoCircle} />}
			</Box>
		</>
	);
}

type UIState = {
	icon: IconName;
	toolTip: string;
	loading?: boolean;
};

function calcState(numTodos: number | undefined, isLoadingContext: boolean, canShowLoading: boolean): UIState | null {
	if (numTodos !== undefined) {
		if (numTodos > 0) {
			return {
				icon: 'list',
				toolTip: t`Det finns uppgifter att göra`,
			};
		}

		return null;
	}

	if (isLoadingContext && !canShowLoading) {
		return null;
	}

	if (isLoadingContext) {
		return {
			loading: true,
			icon: 'list',
			toolTip: t`Laddar uppgifter att göra...`,
		};
	}

	return {
		icon: 'warning',
		toolTip: t`Kunde inte ladda uppgifter att göra`,
	};
}
